import React, { useState } from 'react'
import { useFormikContext } from 'formik'

const AutoSubmitRow: React.FC = () => {
    const { values, initialValues, submitForm } = useFormikContext()

    const [th, setTh] = useState<any>(null)

    React.useEffect(() => {
        if (values !== initialValues) {
            if (th) {
                clearTimeout(th)
            }

            setTh(setTimeout(() => submitForm(), 1000))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, initialValues, submitForm])

    return null
}

export default AutoSubmitRow
