import React from 'react'
import {
    CakeIcon,
    CalendarIcon,
    ChartBarIcon,
    ClipboardListIcon,
    CogIcon,
    IdentificationIcon,
    TagIcon,
    UserAddIcon,
    ViewGridIcon,
} from '@heroicons/react/outline'
import {
    SettingsMenuItemGate,
    UserMenuItemGate,
    PermissionMenuItemGate,
    ProcedureMenuItemGate,
    PriceListMenuItemGate,
    PatientCreateMenuItemGate,
    PatientListMenuItemGate,
    PatientMenuItemGate,
    OperationMenuItemGate,
    OperationCreateMenuItemGate,
    OperationIndexPerformedMenuItemGate,
    OperationOtherIndexMenuItemGate,
    OperationOtherCreateMenuItemGate,
    ScheduleMenuItemGate,
    ScheduleApprovedMenuItemGate,
    SchedulePlannerMenuItemGate,
    PreparedOperationMenuItemGate,
    SurgeryListMenuItemGate,
    CardListMenuItemGate,
    ActivityLogMenuItemGate,
    LiveViewMenuItemGate,
    ClosureTemplateMenuItemGate,
    DietListMenuItemGate,
} from 'gates'
import * as PERMISSIONS from 'constants/permissions'

export type Item = {
    name: string
    to: string
    icon: React.FC<any>
    exact?: boolean
    Gate: React.FC<any>
    children: SubItem[]
    permission?: string
}

export type SubItem = {
    name: string
    to: string
    matches?: string[]
    exclude?: string[]
    exact?: boolean
    Gate: React.FC<any>
}

export const SIDEBAR_ITEMS: Item[] = [
    {
        name: 'Statystyki',
        to: '#',
        icon: ChartBarIcon,
        Gate: ({ children }) => children,
        permission: PERMISSIONS.SEE_STATISTICS,
        children: [
            {
                name: 'Podsumowanie',
                to: '/statistics/dashboard',
                Gate: ({ children }) => children,
            },
            {
                name: 'Zabiegi',
                to: '/statistics/surgeries',
                Gate: ({ children }) => children,
            },
            {
                name: 'Lekarze',
                to: '/statistics/doctors',
                Gate: ({ children }) => children,
            },
            {
                name: 'Anestezjolodzy',
                to: '/statistics/anesthesiologists',
                Gate: ({ children }) => children,
            },
        ],
    },
    {
        name: 'Przygotowywanie pacjenta',
        to: '/caregiver',
        exact: true,
        icon: TagIcon,
        Gate: PreparedOperationMenuItemGate,
        children: [],
    },
    {
        name: 'Lista operacji',
        to: '/surgeries',
        exact: true,
        icon: ClipboardListIcon,
        Gate: SurgeryListMenuItemGate,
        children: [],
    },
    {
        name: 'Lista diet',
        to: '/diets',
        icon: CakeIcon,
        Gate: DietListMenuItemGate,
        children: [],
    },
    {
        name: 'Plan operacyjny',
        to: '#',
        icon: CalendarIcon,
        Gate: ScheduleMenuItemGate,
        children: [
            {
                name: 'Podgląd planu',
                to: '/schedule',
                matches: ['/schedule', '/schedule/hourly'],
                exact: true,
                Gate: ScheduleApprovedMenuItemGate,
            },
            {
                name: 'Edycja planu',
                to: '/schedule/edit',
                matches: ['/schedule/edit', '/schedule/hourly/edit'],
                exact: true,
                Gate: SchedulePlannerMenuItemGate,
            },
        ],
    },
    {
        name: 'Operacje',
        to: '#',
        icon: IdentificationIcon,
        Gate: OperationMenuItemGate,
        children: [
            {
                name: 'Dodaj operację',
                to: '/surgeries/add',
                exact: true,
                Gate: OperationCreateMenuItemGate,
            },
            {
                name: 'Wykonane operacje',
                to: '/surgeries/performed',
                exact: true,
                Gate: OperationIndexPerformedMenuItemGate,
            },
            {
                name: 'Dodaj operację spoza cennika',
                to: '/surgeries/drafts/add',
                exact: true,
                Gate: OperationOtherCreateMenuItemGate,
            },
            {
                name: 'Operacje spoza cennika',
                to: '/surgeries/drafts',
                exact: true,
                Gate: OperationOtherIndexMenuItemGate,
            },
            {
                name: 'Szablony opisu operacji',
                to: '/closures/templates',
                exact: false,
                Gate: ClosureTemplateMenuItemGate,
            },
        ],
    },
    {
        name: 'Pacjenci',
        to: '#',
        icon: UserAddIcon,
        Gate: PatientMenuItemGate,
        children: [
            {
                name: 'Dodaj pacjenta',
                to: '/patients/add',
                exact: true,
                Gate: PatientCreateMenuItemGate,
            },
            {
                name: 'Lista pacjentów',
                to: '/patients',
                exclude: ['/patients/add'],
                Gate: PatientListMenuItemGate,
            },
        ],
    },
    {
        name: 'Plan operacyjny (live)',
        to: '/live-view',
        exact: true,
        icon: ViewGridIcon,
        Gate: LiveViewMenuItemGate,
        children: [],
    },
    {
        name: 'Ustawienia',
        to: '#',
        icon: CogIcon,
        Gate: SettingsMenuItemGate,
        children: [
            {
                name: 'Lista użytkowników',
                to: '/settings/users',
                matches: ['/settings/users'],
                Gate: UserMenuItemGate,
            },
            {
                name: 'Karty użytkowników',
                to: '/settings/cards',
                matches: ['/settings/cards'],
                Gate: CardListMenuItemGate,
            },
            {
                name: 'Role i uprawnienia',
                to: '/settings/roles',
                matches: ['/settings/roles', '/settings/specializations'],
                Gate: PermissionMenuItemGate,
            },
            {
                name: 'Operacje',
                to: '/settings/procedures',
                matches: [
                    '/settings/procedures',
                    '/settings/procedure-categories',
                    '/settings/procedure-types',
                    '/settings/procedure-costs',
                    '/settings/payers',
                ],
                Gate: ProcedureMenuItemGate,
            },
            {
                name: 'Cennik',
                to: '/settings/price-list',
                matches: ['/settings/price-list'],
                Gate: PriceListMenuItemGate,
            },
            {
                name: 'Aktywność użytkowników',
                to: '/activity-log',
                exact: true,
                Gate: ActivityLogMenuItemGate,
            },
        ],
    },
]
