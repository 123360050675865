export const ADMIN_ROLE = 'admin'

export const DOCTOR_FUNCTION = 'doctor function'
export const CAREGIVER_FUNCTION = 'caregiver function'
export const NURSE_FUNCTION = 'nurse function'
export const ANESTHESIOLOGIST_FUNCTION = 'anesthesiologist function'
export const ANESTHESIOLOGIST_NURSE_FUNCTION = 'anesthesiologist nurse function'
export const PLANNER_FUNCTION = 'planner'
export const OPERATION_NURSE_FUNCTION = 'operation nurse function'
export const ADMIN_FUNCTION = 'admin function'
export const TV_FUNCTION = 'tv function'
export const REHABILITATOR_FUNCTION = 'rehabilitator function'

export const PROCEDURE_TASK_RELATED_ROLES = [
    CAREGIVER_FUNCTION,
    NURSE_FUNCTION,
    ANESTHESIOLOGIST_FUNCTION,
]

export const FUNCTIONS = [
    DOCTOR_FUNCTION,
    CAREGIVER_FUNCTION,
    NURSE_FUNCTION,
    ANESTHESIOLOGIST_FUNCTION,
    ANESTHESIOLOGIST_NURSE_FUNCTION,
    PLANNER_FUNCTION,
    OPERATION_NURSE_FUNCTION,
    ADMIN_FUNCTION,
    TV_FUNCTION,
    REHABILITATOR_FUNCTION,
]

export const ASSIGN_ROLES = 'assign roles'
export const CREATE_ROLES = 'create roles'
export const DELETE_ROLES = 'delete roles'
export const UPDATE_ROLES = 'update roles'
export const INDEX_ROLES = 'index roles'
export const SHOW_ROLES = 'show roles'

export const CREATE_USERS = 'create users'
export const DELETE_USERS = 'delete users'
export const UPDATE_USERS = 'update users'
export const INDEX_USERS = 'index users'
export const SHOW_USERS = 'show users'
export const ACTIVATE_USERS = 'activate users'
export const RESEND_INVITATION_LINK = 'resend invitation link'

export const CREATE_PROCEDURE_CATEGORIES = 'create procedure categories'
export const DELETE_PROCEDURE_CATEGORIES = 'delete procedure categories'
export const UPDATE_PROCEDURE_CATEGORIES = 'update procedure categories'
export const INDEX_PROCEDURE_CATEGORIES = 'index procedure categories'
export const SHOW_PROCEDURE_CATEGORIES = 'show procedure categories'

export const CREATE_PROCEDURE_TYPES = 'create procedure types'
export const DELETE_PROCEDURE_TYPES = 'delete procedure types'
export const UPDATE_PROCEDURE_TYPES = 'update procedure types'
export const INDEX_PROCEDURE_TYPES = 'index procedure types'
export const SHOW_PROCEDURE_TYPES = 'show procedure types'

export const CREATE_PROCEDURES = 'create procedures'
export const DELETE_PROCEDURES = 'delete procedures'
export const UPDATE_PROCEDURES = 'update procedures'
export const INDEX_PROCEDURES = 'index procedures'
export const SHOW_PROCEDURES = 'show procedures'

export const CREATE_PAYERS = 'create payers'
export const DELETE_PAYERS = 'delete payers'
export const UPDATE_PAYERS = 'update payers'
export const INDEX_PAYERS = 'index payers'
export const SHOW_PAYERS = 'show payers'
export const SELECT_NFZ_PAYER = 'select nfz payer'

export const CREATE_SPECIALIZATIONS = 'create specializations'
export const DELETE_SPECIALIZATIONS = 'delete specializations'
export const UPDATE_SPECIALIZATIONS = 'update specializations'
export const INDEX_SPECIALIZATIONS = 'index specializations'
export const SHOW_SPECIALIZATIONS = 'show specializations'

export const CREATE_IMPLANT_MANUFACTURERS = 'create implant manufacturers'
export const DELETE_IMPLANT_MANUFACTURERS = 'delete implant manufacturers'
export const UPDATE_IMPLANT_MANUFACTURERS = 'update implant manufacturers'
export const INDEX_IMPLANT_MANUFACTURERS = 'index implant manufacturers'
export const SHOW_IMPLANT_MANUFACTURERS = 'show implant manufacturers'

export const CREATE_IMPLANTS = 'create implants'
export const DELETE_IMPLANTS = 'delete implants'
export const UPDATE_IMPLANTS = 'update implants'
export const INDEX_IMPLANTS = 'index implants'
export const SHOW_IMPLANTS = 'show implants'

export const CREATE_EQUIPMENT = 'create equipment'
export const DELETE_EQUIPMENT = 'delete equipment'
export const UPDATE_EQUIPMENT = 'update equipment'
export const INDEX_EQUIPMENT = 'index equipment'
export const SHOW_EQUIPMENT = 'show equipment'

export const CREATE_EXPENSES = 'create expenses'
export const DELETE_EXPENSES = 'delete expenses'
export const UPDATE_EXPENSES = 'update expenses'
export const INDEX_EXPENSES = 'index expenses'
export const SHOW_EXPENSES = 'show expenses'

export const CREATE_PATIENTS = 'create patients'
export const DELETE_PATIENTS = 'delete patients'
export const UPDATE_PATIENTS = 'update patients'
export const INDEX_PATIENTS = 'index patients'
export const SHOW_PATIENTS = 'show patients'

export const CREATE_OPERATIONS = 'create operations'
export const DELETE_OPERATIONS = 'delete operations'
export const UPDATE_OPERATIONS = 'update operations'
export const INDEX_OPERATIONS = 'index operations'
export const SHOW_OPERATIONS = 'show operations'
export const EXPORT_OPERATIONS = 'export operations'
export const INDEX_OTHERS_OPERATIONS = 'index others operations'
export const CHANGE_PERFORMED_OPERATION_STATUS =
    'change performed operation status'
export const CHANGE_EVENTS_MANUALLY = 'change events manually'
export const RESTORE_STATUS = 'restore event'
export const CREATE_FINISHED_EVENT = 'create finished event'

export const CREATE_OPERATION_TASKS = 'create operation tasks'
export const DELETE_OPERATION_TASKS = 'delete operation tasks'
export const UPDATE_OPERATION_TASKS = 'update operation tasks'
export const INDEX_OPERATION_TASKS = 'index operation tasks'
export const SHOW_OPERATION_TASKS = 'show operation tasks'

export const CREATE_OPERATING_ROOMS = 'create operating rooms'
export const DELETE_OPERATING_ROOMS = 'delete operating rooms'
export const UPDATE_OPERATING_ROOMS = 'update operating rooms'
export const INDEX_OPERATING_ROOMS = 'index operating rooms'
export const SHOW_OPERATING_ROOMS = 'show operating rooms'

export const CREATE_OPERATION_CLOSURE = 'create operation closure'
export const UPDATE_OPERATION_CLOSURE = 'update operation closure'
export const SHOW_OPERATION_CLOSURE = 'show operation closure'

export const INDEX_PERMISSIONS = 'index permissions'

export const INDEX_ICD_CODES = 'index icd codes'

export const INDEX_COMMENTS = 'index comments'
export const CREATE_COMMENTS = 'create comments'

export const INDEX_SCHEDULES = 'index schedules'
export const SHOW_SCHEDULES = 'show schedules'
export const UPDATE_SCHEDULES = 'update schedules'
export const SEND_SCHEDULE_SMS = 'send schedule sms'

export const INDEX_BLOCKED_DAYS = 'index blocked days'
export const CREATE_BLOCKED_DAYS = 'create blocked days'
export const DELETE_BLOCKED_DAYS = 'delete blocked days'

export const INDEX_APPROVED_DAYS = 'index approved days'
export const CREATE_APPROVED_DAYS = 'create approved days'

export const INDEX_ANESTHESIOLOGIST_SCHEDULES =
    'index anesthesiologist schedules'
export const CREATE_ANESTHESIOLOGIST_SCHEDULES =
    'create anesthesiologist schedules'
export const UPDATE_ANESTHESIOLOGIST_SCHEDULES =
    'update anesthesiologist schedules'

export const VIEW_OPERATION_EVENTS_IN_DUTY_ROOM = 'duty room events'
export const VIEW_OPERATION_EVENTS_IN_CORRIDOR = 'corridor events'
export const VIEW_OPERATION_EVENTS_IN_WAKEUP_ROOM = 'wakeup room events'
export const VIEW_OPERATION_EVENTS_IN_OPERATING_ROOM = 'operating room events'
export const REGISTER_ANESTHESIOLOGIST_WORKTIME =
    'register anesthesiologist worktime'
export const VIEW_OPERATION_EVENTS_LIVE_VIEW = 'live view events'
export const VIEW_NOTIFICATIONS = 'view notifications'

export const TABLET_PERMISSIONS = [
    VIEW_OPERATION_EVENTS_IN_DUTY_ROOM,
    VIEW_OPERATION_EVENTS_IN_CORRIDOR,
    VIEW_OPERATION_EVENTS_IN_WAKEUP_ROOM,
    VIEW_OPERATION_EVENTS_IN_OPERATING_ROOM,
]

export const CREATE_IDENTIFICATION_CARDS = 'create identification cards'
export const DELETE_IDENTIFICATION_CARDS = 'delete identification cards'
export const UPDATE_IDENTIFICATION_CARDS = 'update identification cards'
export const INDEX_IDENTIFICATION_CARDS = 'index identification cards'
export const SHOW_IDENTIFICATION_CARDS = 'show identification cards'

export const INDEX_ACTIVITY_LOGS = 'index activity logs'
export const CANCEL_OPERATIONS = 'cancel operations'
export const REJECT_OPERATIONS = 'reject operations'
export const RENEW_OPERATIONS = 'renew operations'

export const SEE_PRICES = 'see prices'

export const INDEX_DIETS = 'index diets'

export const SEE_STATISTICS = 'see statistics'
export const VIEW_TIME_VIEW = 'view time view'
export const SEE_OPERATION_DURATIONS = 'see durations'
export const VIEW_COMMENT_HISTORY = 'view comment history'
export const OPERATION_ACCOUNTANCY = 'operation accountancy'
