import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { UsersIcon } from '@heroicons/react/solid'

import { roundedPrice } from 'helpers'

import { ReactComponent as IconMedicine75 } from 'assets/icon-medicine-75.svg'
import { ReactComponent as IconMedicine89 } from 'assets/icon-medicine-89.svg'
import { ReactComponent as IconOperationRoomCleaning } from 'assets/operationProgressIcons/operation-room-cleaning.svg'
import { ReactComponent as IconReturningFromOperatingBlock } from 'assets/operationProgressIcons/operation-returning-from-operating-block.svg'
import { ReactComponent as IconOperationAwaitingForCall } from 'assets/operationProgressIcons/operation-awaiting-for-call.svg'
import { ReactComponent as IconOperationAdmittedToOperatingBlock } from 'assets/operationProgressIcons/operation-admitted-to-operating-block.svg'

import type { StatisticsDashboardResponse } from 'api/types'

const StatisticsDashboard: React.FC<{
    data: StatisticsDashboardResponse
}> = ({ data }) => {
    const operations = useMemo(() => {
        return data.operations.find((item) => item.type === 'all')
    }, [data.operations])

    const rejected = useMemo(() => {
        return data.operations.find((item) => item.type === 'rejected')
    }, [data.operations])

    return (
        <div className="space-y-4">
            <div className="p-6 rounded-md bg-white">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                    Zabiegi
                </h2>
                <div className="mt-5 grid grid-cols-1 gap-5 2xl:grid-cols-2">
                    <BorderedItem>
                        <Item
                            title="Liczba zabiegów"
                            icon={
                                <ItemIcon
                                    icon={
                                        <IconMedicine75 className="w-5 h-5 text-white component-disabled:text-gray-400" />
                                    }
                                    bgOuter="bg-blue-50"
                                    bgInner="bg-blue-800"
                                    disabledBgOuter="bg-gray-50"
                                    disabledBgInner="bg-gray-200"
                                />
                            }
                            left={
                                <ItemRow>
                                    <ItemLabelledValue
                                        spaceX={4}
                                        label="Wykonanie:"
                                    >
                                        <ItemValue>
                                            {operations?.performed ||
                                            operations?.performed === 0
                                                ? operations.performed
                                                : '-'}
                                        </ItemValue>
                                    </ItemLabelledValue>
                                    <ItemLabelledValue spaceX={4} label="Plan:">
                                        <ItemValue>
                                            {operations?.count ||
                                            operations?.count === 0
                                                ? operations.count
                                                : '-'}
                                        </ItemValue>
                                    </ItemLabelledValue>
                                </ItemRow>
                            }
                            right={
                                <ItemValue>
                                    {operations?.count
                                        ? operations?.performed ||
                                          operations?.performed === 0
                                            ? Math.round(
                                                  (operations.performed /
                                                      operations.count) *
                                                      100
                                              )
                                            : '-'
                                        : operations?.count === 0
                                        ? 0
                                        : '-'}
                                    %
                                </ItemValue>
                            }
                            disabled={typeof operations === 'undefined'}
                        />
                    </BorderedItem>
                    <BorderedItem>
                        <Item
                            title="Koszty zabiegów"
                            icon={
                                <ItemIcon
                                    icon={
                                        <IconMedicine75 className="w-5 h-5 text-white component-disabled:text-gray-400" />
                                    }
                                    bgOuter="bg-yellow-100"
                                    bgInner="bg-yellow-500"
                                    disabledBgOuter="bg-gray-50"
                                    disabledBgInner="bg-gray-200"
                                />
                            }
                            left={
                                <ItemRow>
                                    <ItemLabelledValue
                                        spaceX={4}
                                        label="Wykonanie:"
                                    >
                                        <ItemValue>
                                            {operations?.performed_sum ||
                                            operations?.performed_sum === 0
                                                ? roundedPrice(
                                                      operations.performed_sum
                                                  )
                                                : '-'}
                                        </ItemValue>
                                    </ItemLabelledValue>
                                    <ItemLabelledValue spaceX={4} label="Plan:">
                                        <ItemValue>
                                            {operations?.total_sum ||
                                            operations?.total_sum === 0
                                                ? roundedPrice(
                                                      operations.total_sum
                                                  )
                                                : '-'}
                                        </ItemValue>
                                    </ItemLabelledValue>
                                </ItemRow>
                            }
                            right={
                                <ItemValue>
                                    {operations?.total_sum
                                        ? operations?.performed_sum ||
                                          operations?.performed_sum === 0
                                            ? Math.round(
                                                  (operations.performed_sum /
                                                      operations.total_sum) *
                                                      100
                                              )
                                            : '-'
                                        : operations?.total_sum === 0
                                        ? 0
                                        : '-'}
                                    %
                                </ItemValue>
                            }
                            disabled={typeof operations === 'undefined'}
                        />
                    </BorderedItem>
                    <BorderedItem>
                        <Item
                            title="Zrzucone zabiegi"
                            icon={
                                <ItemIcon
                                    icon={
                                        <IconMedicine89 className="w-5 h-5 text-gray-900 component-disabled:text-gray-400" />
                                    }
                                    bgOuter="bg-gray-700"
                                    bgInner="bg-gray-300"
                                    disabledBgOuter="bg-gray-50"
                                    disabledBgInner="bg-gray-200"
                                />
                            }
                            left={
                                <ItemValue>
                                    {rejected?.count || rejected?.count === 0
                                        ? rejected.count
                                        : '-'}
                                </ItemValue>
                            }
                            right={
                                <ItemLabelledValue label="Suma:">
                                    <ItemValue>
                                        {rejected?.total_sum ||
                                        rejected?.total_sum === 0
                                            ? roundedPrice(rejected.total_sum)
                                            : '-'}
                                    </ItemValue>
                                </ItemLabelledValue>
                            }
                            disabled={typeof rejected === 'undefined'}
                        />
                    </BorderedItem>
                </div>
                <div className="bg-gray-300 h-px mt-5" />
                <div className="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2 2xl:grid-cols-3">
                    <BorderedItem>
                        <Item
                            title="Średni czas wezwania pacjenta"
                            icon={
                                <ItemIcon
                                    icon={
                                        <IconOperationAwaitingForCall className="w-9 h-9 text-white component-disabled:text-gray-400" />
                                    }
                                    bgOuter="bg-red-50"
                                    bgInner="bg-red-800"
                                    disabledBgOuter="bg-gray-50"
                                    disabledBgInner="bg-gray-200"
                                />
                            }
                            left={
                                <ItemRow>
                                    <ItemValue>
                                        {
                                            data.average_durations
                                                .transfer_to_block
                                        }
                                    </ItemValue>
                                </ItemRow>
                            }
                        />
                    </BorderedItem>
                    <BorderedItem>
                        <Item
                            title="Średni czas oczekiwania na lekarza"
                            icon={
                                <ItemIcon
                                    icon={
                                        <IconOperationAdmittedToOperatingBlock className="w-9 h-9 text-white component-disabled:text-gray-400" />
                                    }
                                    bgOuter="bg-red-50"
                                    bgInner="bg-red-800"
                                    disabledBgOuter="bg-gray-50"
                                    disabledBgInner="bg-gray-200"
                                />
                            }
                            left={
                                <ItemRow>
                                    <ItemValue>
                                        {
                                            data.average_durations
                                                .waiting_for_doctor
                                        }
                                    </ItemValue>
                                </ItemRow>
                            }
                        />
                    </BorderedItem>
                    <BorderedItem>
                        <Item
                            title="Średni czas sprzątania"
                            icon={
                                <ItemIcon
                                    icon={
                                        <IconOperationRoomCleaning className="w-9 h-9 text-purple-900 component-disabled:text-gray-400" />
                                    }
                                    bgOuter="bg-purple-800"
                                    bgInner="bg-purple-50"
                                    disabledBgOuter="bg-gray-50"
                                    disabledBgInner="bg-gray-200"
                                />
                            }
                            left={
                                <ItemRow>
                                    <ItemValue>
                                        {data.average_durations.cleaning}
                                    </ItemValue>
                                </ItemRow>
                            }
                            disabled={typeof operations === 'undefined'}
                        />
                    </BorderedItem>
                    <BorderedItem>
                        <Item
                            title="Średni czas odebrania pacjenta"
                            icon={
                                <ItemIcon
                                    icon={
                                        <IconReturningFromOperatingBlock className="w-9 h-9 text-white component-disabled:text-gray-400" />
                                    }
                                    bgOuter="bg-purple-50"
                                    bgInner="bg-purple-800"
                                    disabledBgOuter="bg-gray-50"
                                    disabledBgInner="bg-gray-200"
                                />
                            }
                            left={
                                <ItemRow>
                                    <ItemValue>
                                        {data.average_durations.pickup_patient}
                                    </ItemValue>
                                </ItemRow>
                            }
                            disabled={typeof operations === 'undefined'}
                        />
                    </BorderedItem>
                </div>
            </div>

            <div className="p-6 rounded-md bg-white">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                    Sale
                </h2>

                <div className="mt-5 space-y-5">
                    {[1, 2, 3].map((roomNumber, index) => {
                        const item =
                            index in data.rooms ? data.rooms[index] : null

                        return (
                            <BorderedItem key={roomNumber}>
                                <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 2xl:grid-cols-4">
                                    <Item
                                        title="Średnia dzienna zajętość"
                                        disabled={!item}
                                        icon={
                                            <ItemIcon
                                                icon={
                                                    <span className="text-2xl leading-8 font-semibold">
                                                        {roomNumber}
                                                    </span>
                                                }
                                                bgOuter="bg-gray-100"
                                                bgInner="bg-gray-100"
                                                disabledBgOuter="bg-gray-50"
                                                disabledBgInner="bg-gray-50"
                                            />
                                        }
                                        left={
                                            <ItemRow className="space-x-4 divide-x divide-gray-300">
                                                <ItemValue
                                                    benchmark={
                                                        item &&
                                                        `${Math.round(
                                                            item.planned_day_usage_percent *
                                                                100
                                                        )}%`
                                                    }
                                                >
                                                    {item
                                                        ? `${Math.round(
                                                              item.real_day_usage_percent *
                                                                  100
                                                          )}%`
                                                        : '-'}
                                                </ItemValue>
                                                <span className="pl-4">
                                                    <ItemValue
                                                        benchmark={
                                                            item &&
                                                            `${item.planned_day_usage}h`
                                                        }
                                                    >
                                                        {item
                                                            ? `${item.real_day_usage}h`
                                                            : '-'}
                                                    </ItemValue>
                                                </span>
                                            </ItemRow>
                                        }
                                    />
                                    <Item
                                        title="Liczba zabiegów"
                                        disabled={!item}
                                        left={
                                            <ItemValue
                                                benchmark={item && item.total}
                                            >
                                                {item ? item.performed : '-'}
                                            </ItemValue>
                                        }
                                    />
                                    <Item
                                        title="Średnia liczba zabiegów"
                                        disabled={!item}
                                        left={
                                            <ItemValue>
                                                {item ? item.average : '-'}
                                            </ItemValue>
                                        }
                                    />
                                    <Item
                                        title="Średnia suma zabiegów"
                                        disabled={!item}
                                        left={
                                            <ItemLabelledValue label="Suma:">
                                                <ItemValue
                                                    benchmark={
                                                        item &&
                                                        roundedPrice(
                                                            item.total_sum
                                                        )
                                                    }
                                                >
                                                    {item
                                                        ? roundedPrice(
                                                              item.performed_sum
                                                          )
                                                        : '-'}
                                                </ItemValue>
                                            </ItemLabelledValue>
                                        }
                                    />
                                </div>
                            </BorderedItem>
                        )
                    })}
                </div>
            </div>
            <div className="p-6 rounded-md bg-white">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                    Przygotowanie pacjenta
                </h2>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
                    <BorderedItem>
                        <Item
                            title="Zadania do wykonania"
                            icon={
                                <ItemIcon
                                    icon={
                                        <UsersIcon className="h-5 w-5 text-white" />
                                    }
                                    bgOuter="bg-blue-50"
                                    bgInner="bg-blue-800"
                                    disabledBgOuter="bg-gray-50"
                                    disabledBgInner="bg-gray-200"
                                />
                            }
                            left={<ItemValue>{data.tasks.todo}</ItemValue>}
                        />
                    </BorderedItem>
                    <BorderedItem>
                        <Item
                            title="Zadania wykonane"
                            icon={
                                <ItemIcon
                                    icon={
                                        <UsersIcon className="h-5 w-5 text-white" />
                                    }
                                    bgOuter="bg-green-50"
                                    bgInner="bg-green-800"
                                    disabledBgOuter="bg-gray-50"
                                    disabledBgInner="bg-gray-200"
                                />
                            }
                            left={<ItemValue>{data.tasks.done}</ItemValue>}
                        />
                    </BorderedItem>
                    <BorderedItem>
                        <Item
                            title="Zadania oczekujące"
                            icon={
                                <ItemIcon
                                    icon={
                                        <UsersIcon className="h-5 w-5 text-white" />
                                    }
                                    bgOuter="bg-gray-50"
                                    bgInner="bg-gray-400"
                                    disabledBgOuter="bg-gray-50"
                                    disabledBgInner="bg-gray-200"
                                />
                            }
                            left={<ItemValue>{data.tasks.awaiting}</ItemValue>}
                        />
                    </BorderedItem>
                    <BorderedItem>
                        <Item
                            title="Zadania pilne"
                            icon={
                                <ItemIcon
                                    icon={
                                        <UsersIcon className="h-5 w-5 text-white" />
                                    }
                                    bgOuter="bg-red-50"
                                    bgInner="bg-red-800"
                                    disabledBgOuter="bg-gray-50"
                                    disabledBgInner="bg-gray-200"
                                />
                            }
                            left={<ItemValue>{data.tasks.critical}</ItemValue>}
                        />
                    </BorderedItem>
                </div>
            </div>
            <div className="p-6 rounded-md bg-white">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                    Lekarze
                </h2>
                <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                    <DoctorsLink disabled={false} />
                </div>
            </div>
        </div>
    )
}

const BorderedItem: React.FC = ({ children }) => (
    <div className="border border-gray-200 rounded-lg bg-white">{children}</div>
)

type ItemProps = {
    title: string
    icon?: JSX.Element
    left?: JSX.Element
    right?: JSX.Element
    content?: React.ReactNode
    disabled?: boolean
}
const Item: React.FC<ItemProps> = ({
    disabled,
    title,
    left,
    icon,
    right,
    content,
}) => {
    return (
        <div
            className={classNames('px-4 py-5', {
                'pointer-events-none text-gray-400 component-disabled':
                    disabled,
            })}
        >
            <div className="flex justify-center">
                {icon}
                <div className="flex-grow ml-4 space-y-1">
                    <p className="text-base leading-6 font-normal">{title}</p>
                    <div className="flex items-baseline justify-between">
                        {left}
                        {right}
                    </div>
                    {!!content && content}
                </div>
            </div>
        </div>
    )
}

const ItemLink: React.FC<{
    to: string
    disabled?: boolean
}> = ({ to, children, disabled }) => {
    return (
        <Link
            to={to}
            className={classNames({
                'pointer-events-none': disabled,
            })}
        >
            {children}
        </Link>
    )
}

const ItemValue: React.FC<{
    className?: string
    benchmark?: string | number | null
}> = ({ className, benchmark, children }) => {
    className = className || 'text-2xl'

    return (
        <span className="flex items-baseline space-x-2 whitespace-nowrap">
            <span
                className={classNames(
                    'text-gray-700 component-disabled:text-gray-300 leading-8 font-semibold',
                    className
                )}
            >
                {children}
            </span>
            {!!benchmark && (
                <span className="text-xl text-gray-500 leading-7 component-disabled:text-gray-300">
                    ({benchmark})
                </span>
            )}
        </span>
    )
}

const ItemLabelledValue: React.FC<{
    label: string
    spaceX?: number
    value?: string
    renderTotal?: () => void
}> = ({ label, value, renderTotal, spaceX, children }) => {
    return (
        <p
            className={`flex space-x-${
                spaceX || 1
            } items-baseline text-sm text-green-600`}
        >
            <span className="text-gray-400 text-base leading-none font-light">
                {label}
            </span>
            {children}
        </p>
    )
}

export const ItemRow: React.FC<{
    className?: string
}> = ({ className, children }) => {
    className = className || 'space-x-8'
    return (
        <div className={classNames('flex items-center', className)}>
            {children}
        </div>
    )
}

const DoctorsLink: React.FC<{
    disabled?: boolean
}> = ({ disabled }) => {
    return (
        <ItemLink to="/statistics/doctors" disabled={disabled}>
            <BorderedItem>
                <Item
                    icon={
                        <ItemIcon
                            icon={
                                <UsersIcon
                                    className="h-5 w-5 text-white"
                                    aria-hidden="true"
                                />
                            }
                            bgOuter="bg-blue-50"
                            bgInner="bg-blue-800"
                            disabledBgOuter="bg-gray-50"
                            disabledBgInner="bg-gray-200"
                            disabled={disabled}
                        />
                    }
                    title="Statystyki lekarzy"
                    disabled={disabled}
                />
            </BorderedItem>
        </ItemLink>
    )
}

const ItemIcon: React.FC<{
    icon: JSX.Element
    bgOuter: string
    bgInner: string
    disabledBgOuter: string
    disabledBgInner: string
    disabled?: boolean
}> = ({
    icon,
    bgOuter,
    bgInner,
    disabled,
    disabledBgOuter,
    disabledBgInner,
}) => {
    return (
        <div
            className={classNames({
                'component-disabled': disabled,
            })}
        >
            <div
                className={classNames(
                    'flex items-center justify-center rounded-md w-14 h-14',
                    bgOuter,
                    `component-disabled:${disabledBgOuter}`
                )}
            >
                <div
                    className={classNames(
                        'flex items-center justify-center w-11 h-11 rounded-full',
                        bgInner,
                        `component-disabled:${disabledBgInner}`
                    )}
                >
                    {icon}
                </div>
            </div>
        </div>
    )
}

export default StatisticsDashboard
export { Item, BorderedItem, ItemIcon, ItemValue, ItemLabelledValue }
