import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'

import axios from 'api/axios'
import {
    GetProcedureCategoryListResponse,
    PostProcedureCategory,
    ProcedureCategory,
    ResponseData,
    ResponseError,
} from 'api/types'

export const getProcedureCategories = <T>(filters?: {}): Promise<T> =>
    axios.get(
        '/procedure-categories?' + new URLSearchParams(filters).toString()
    )

export const useGetProcedureCategories = <T = GetProcedureCategoryListResponse>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        ['procedure-categories', filters],
        () => getProcedureCategories<T>(filters),
        options
    )

export const useGetProcedureCategory = <
    T = ProcedureCategory,
    E = ResponseError
>(
    id: string,
    options?: UseQueryOptions<ResponseData<T>, E>
) =>
    useQuery<ResponseData<T>, E>(
        ['procedure-categories', id],
        () => axios.get(`/procedure-categories/${id}`),
        options
    )

export const createProcedureCategory = <R1, R2>(data: R1): Promise<R2> =>
    axios.post('/procedure-categories', data)

export const useCreateProcedureCategory = <
    R1 = PostProcedureCategory,
    R2 = ResponseData<ProcedureCategory>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => createProcedureCategory<R1, R2>(data))

export const updateProcedureCategory = <R1, R2>(
    id: number,
    data: R1
): Promise<R2> => axios.patch('/procedure-categories/' + id, data)

export const useUpdateProcedureCategory = <
    R1 extends ProcedureCategory,
    R2 = ResponseData<ProcedureCategory>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => updateProcedureCategory<R1, R2>(data.id, data))
