import {
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import client from 'api/axios'
import {
    ResponseData,
    ResponseError,
    StatisticsAnesthesiologistsResponse,
    StatisticsDashboardRequest,
    StatisticsDashboardResponse,
    StatisticsDoctorsResponse,
    StatisticsOperationResponse,
    StatisticsOperationsResponse,
} from 'api/types'

export const useStatisticsDashboard = <
    F extends StatisticsDashboardRequest,
    R = ResponseData<StatisticsDashboardResponse>
>(
    filters?: F,
    options?: UseQueryOptions<R, ResponseError>
): UseQueryResult<R, ResponseError> =>
    useQuery<R, ResponseError>(
        ['statistics-dashboard', filters],
        () =>
            client.get(
                '/statistics/dashboard' +
                    (filters ? '?' + qs.stringify(filters) : '')
            ),
        options
    )

export const useInfiniteStatisticsOperations = <
    T = StatisticsOperationsResponse
>(
    filters?: {},
    options?: UseInfiniteQueryOptions<T, ResponseError>
): UseInfiniteQueryResult<T, ResponseError> =>
    useInfiniteQuery<T, ResponseError>(
        ['statistics-operations', 'index', filters],
        ({ pageParam = 1 }) =>
            client.get('/statistics/operations', {
                params: { ...filters, page: pageParam },
            }),
        options
    )

export const useInfiniteStatisticsDoctors = <T = StatisticsDoctorsResponse>(
    filters?: {},
    options?: UseInfiniteQueryOptions<T, ResponseError>
): UseInfiniteQueryResult<T, ResponseError> =>
    useInfiniteQuery<T, ResponseError>(
        ['statistics-doctors', 'index', filters],
        ({ pageParam = 1 }) =>
            client.get('/statistics/doctors', {
                params: { ...filters, page: pageParam },
            }),
        options
    )

export const useInfiniteStatisticsAnesthesiologists = <
    T = StatisticsAnesthesiologistsResponse
>(
    filters?: {},
    options?: UseInfiniteQueryOptions<T, ResponseError>
): UseInfiniteQueryResult<T, ResponseError> =>
    useInfiniteQuery<T, ResponseError>(
        ['statistics-anesthesiologists', 'index', filters],
        ({ pageParam = 1 }) =>
            client.get('/statistics/anesthesiologist', {
                params: { ...filters, page: pageParam },
            }),
        options
    )

export const useGetStatisticsOperation = <
    T extends StatisticsOperationResponse,
    E extends ResponseError
>(
    id?: number | string,
    options?: UseQueryOptions<ResponseData<T>, E>
): UseQueryResult<ResponseData<T>, E> =>
    useQuery<ResponseData<T>, E>(
        ['statistics-operation', id],
        () => client.get(`/statistics/operations/${id}`),
        options
    )

export const exportStatisticOperations = (filters?: {}) => {
    return client.get(
        '/statistics/operations?export=true' +
            (filters ? '&' + qs.stringify(filters, {}) : ''),
        {
            headers: {
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'blob',
        }
    )
}

export const exportStatisticDoctors = (filters?: {}) => {
    return client.get(
        '/statistics/doctors?export=true' +
            (filters ? '&' + qs.stringify(filters, {}) : ''),
        {
            headers: {
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'blob',
        }
    )
}

export const exportStatisticAnesthesiologists = (filters?: {}) => {
    return client.get(
        '/statistics/anesthesiologist?export=true' +
            (filters ? '&' + qs.stringify(filters, {}) : ''),
        {
            headers: {
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'blob',
        }
    )
}
