import {
    useEquipmentPolicy,
    useExpensePolicy,
    useImplantManufacturePolicy,
    useImplantPolicy,
    usePolicy,
} from 'hooks'

export const usePriceListMenuItemPolicyGroup = () => {
    const { user } = usePolicy()
    const implantPolicy = useImplantPolicy()
    const implantManufacturePolicy = useImplantManufacturePolicy()
    const equipmentPolicy = useEquipmentPolicy()
    const expensePolicy = useExpensePolicy()

    return {
        user,
        canAny:
            implantPolicy.canAll ||
            implantManufacturePolicy.canAll ||
            equipmentPolicy.canAll ||
            expensePolicy.canAll,
    }
}
