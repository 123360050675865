import React, { useRef, useState, useMemo, useEffect } from 'react'
import classNames from 'classnames'
import { Listbox } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

import { useEventListener } from 'hooks'
import { InputText } from 'components/ui'
import DropdownOptions from './components/DropdownOptions'
import DropdownFieldProps from './interfaces/DropdownFieldProps'
import { DropdownItem } from './index'

const DROPDOWN_OPTIONS_TRANSITION_DURATION = 100

export const AdmissionTimeDropdown = <T extends DropdownItem>({
    disabled,
    value,
    validator,
    onChange,
    ...props
}: Omit<DropdownFieldProps<T>, 'name'> & {
    name?: string
    value?: T
    validator?: (phrase: string) => T | false
    onChange?: (value?: T) => void
}) => {
    const ref = useRef<HTMLDivElement | null>(null)
    const [inputValue, setInputValue] = useState(value?.name || '')
    const [currentOption, setCurrentOption] = useState<T | undefined>(value)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        !!onChange && onChange(currentOption)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOption])

    const filteredItems = useMemo(() => {
        const sanitizedInputValue = inputValue.replace(':', '').toLowerCase()

        return props.items.filter((item) => {
            const sanitizedName = item.name.replace(':', '').toLowerCase()

            if (sanitizedName.startsWith(sanitizedInputValue)) {
                return true
            }

            if (
                sanitizedInputValue.length === 1 &&
                sanitizedName.startsWith('0') &&
                sanitizedName.slice(1).startsWith(sanitizedInputValue)
            ) {
                return true
            }

            if (
                sanitizedInputValue.length === 2 &&
                Number(sanitizedInputValue) > 23 &&
                sanitizedName.startsWith('0' + sanitizedInputValue)
            ) {
                return true
            }

            if (
                sanitizedInputValue.length === 3 &&
                Number(sanitizedInputValue) > 235 &&
                sanitizedName.startsWith('0') &&
                sanitizedName.slice(1).startsWith(sanitizedInputValue)
            ) {
                return true
            }

            return false
        })
    }, [props.items, inputValue])

    const items =
        currentOption && currentOption.name === inputValue
            ? props.items
            : filteredItems

    const handleClickOutside = (e: Event) => {
        if (
            ref.current &&
            e.target instanceof Node &&
            !ref.current.contains(e.target)
        ) {
            if (inputValue.length && filteredItems.length) {
                setCurrentOption(filteredItems[0])
                setInputValue(filteredItems[0].name)
            } else if (
                inputValue.length &&
                validator &&
                validator(inputValue)
            ) {
                const item = validator(inputValue)
                if (item) {
                    setCurrentOption(item)
                    setInputValue(item.name)
                }
            } else {
                setCurrentOption(undefined)
                setInputValue('')
            }

            setOpen(false)
        }
    }

    useEventListener('mousedown', handleClickOutside, [
        inputValue,
        filteredItems,
    ])

    return (
        <div
            ref={ref}
            className={classNames('relative', {
                'cursor-default': disabled,
            })}
        >
            <InputText
                tabIndex={props.tabIndex}
                type="text"
                value={inputValue}
                placeholder={props.placeholder}
                disabled={disabled}
                hasError={props.hasError}
                pattern="^\d\d?:?\d?\d?$"
                onChange={(e) => {
                    if (!e.target.validity.valid) {
                        return false
                    }

                    setInputValue(e.target.value)
                }}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span
                    className={classNames(
                        'h-5 w-5 pointer-events-auto cursor-pointer',
                        {
                            'text-gray-400 hover:text-gray-500': !open,
                            'text-gray-600': open,
                        }
                    )}
                    onClick={() => {
                        if (!open) {
                            setOpen(true)
                        } else {
                            setOpen(false)
                        }
                    }}
                >
                    {!open && <ChevronDownIcon aria-hidden="true" />}
                    {open && <ChevronUpIcon aria-hidden="true" />}
                </span>
            </div>
            <Listbox
                value={currentOption}
                onChange={(value: T) => {
                    setOpen(false)
                    setTimeout(() => {
                        setCurrentOption(value)
                        setInputValue(value.name)
                    }, DROPDOWN_OPTIONS_TRANSITION_DURATION)
                }}
                disabled={disabled}
            >
                <div className="mt-1 relative">
                    {items.length > 0 && (
                        <DropdownOptions
                            show={open}
                            items={items}
                            fieldValue={currentOption}
                        />
                    )}
                </div>
            </Listbox>
        </div>
    )
}
