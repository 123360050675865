import React from 'react'
import classNames from 'classnames'

import { Spinner, Toggle, TableFooter } from 'components/ui'
import EmptyList from 'components/EmptyList'

import type { UseQueryResult } from 'react-query'
import type { IModal } from 'types'
import type { Payer, ResponseList } from 'api/types'
import type { ListFilterAction } from 'helpers'

export default function SettingsPayerList({
    payers,
    modal,
    setFilters,
    handleToggleActive,
}: {
    payers: UseQueryResult<ResponseList<Payer[]>>
    modal: IModal
    setFilters: React.Dispatch<ListFilterAction>
    handleSubmit: () => void
    handleToggleActive: (id: number, active: boolean) => void
}) {
    if (payers.isSuccess && payers.data.meta.total === 0) {
        return (
            <div className="bg-white p-2 rounded-lg">
                <EmptyList
                    headline="Nic tu jeszcze nie ma"
                    message="Zacznij od stworzenia pierwszego kontrahenta."
                    onClick={modal.openModal}
                />
            </div>
        )
    }

    return (
        <div className="bg-white p-2 rounded-lg">
            <table className="min-w-full">
                <thead className="bg-gray-50 bg-gray-50 border-b">
                    <tr>
                        <th
                            scope="col"
                            className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Id
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Nazwa
                        </th>
                        <th
                            scope="col"
                            className="w-2/12 px-6 py-3 w-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
                        >
                            Liczba operacji
                        </th>
                        <th
                            scope="col"
                            className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Aktywny
                        </th>
                    </tr>
                </thead>
                {payers.isSuccess && (
                    <tbody className="bg-white divide-y divide-gray-200 text-sm leading-5 text-gray-500">
                        {payers.data.data.map((item, index) => (
                            <tr
                                key={index}
                                className={classNames({
                                    'bg-gray-50': index % 2,
                                })}
                            >
                                <td className="pl-6 py-6 whitespace-nowrap">
                                    <div className="">{item.id}</div>
                                </td>
                                <td className="px-6 py-6 whitespace-nowrap">
                                    <span className="text-gray-900 font-medium">
                                        {item.name}
                                    </span>
                                </td>
                                <td className="px-6 py-6 whitespace-nowrap text-center">
                                    <span>{item.procedures_count || '-'}</span>
                                </td>
                                <td className="px-6 py-1 whitespace-nowrap text-right">
                                    <Toggle
                                        checked={item.active}
                                        handleChange={(value) =>
                                            handleToggleActive(
                                                item.id,
                                                !item.active
                                            )
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                )}
            </table>
            {payers.isLoading && (
                <div className="divide-y divide-gray-200 text-sm leading-5">
                    {Array.from(Array(15).keys()).map((item, index) => (
                        <div key={index} className="relative px-6 py-6">
                            {index === 5 && (
                                <div className="absolute inset-0 h-full flex justify-center items-center">
                                    <Spinner className="p-0" />
                                </div>
                            )}
                            <span>&nbsp;</span>
                        </div>
                    ))}
                </div>
            )}
            <TableFooter meta={payers.data?.meta} setFilters={setFilters} />
        </div>
    )
}
