import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import ListLayout from 'layouts/ListLayout'
import { useGetPatient } from 'api'
import { Button, Label, Loader, Toggle } from 'components/ui'
import {
    BorderedItem,
    Item,
    ItemIcon,
    ItemLabelledValue,
    ItemValue,
} from 'components/StatisticsDashboard'
import {
    displayPatientName,
    phoneWithoutPolandPrefix,
    roundedPrice,
} from 'helpers'

import { ReactComponent as IconMedicine75 } from 'assets/icon-medicine-75.svg'
import { ReactComponent as IconMedicine89 } from 'assets/icon-medicine-89.svg'

import type { TTab } from 'components/ListLayoutTabs'
import type { PatientResponse } from 'api/types'

const tabs: TTab[] = [
    {
        name: 'Pacjent',
        to: '/patients/:id/stats',
        current: true,
    },
    {
        name: 'Dane pacjenta',
        to: '/patients/:id/edit',
        current: false,
    },
    {
        name: 'Operacje i zabiegi',
        to: '/patients/:id/surgeries',
        current: false,
    },
    { name: 'Dokumenty', to: '/patients/:id/documents', current: false },
]

export default function PatientStatsContainer() {
    const { id } = useParams()
    const patient = useGetPatient(id, { enabled: !!id })

    if (patient.isLoading) {
        return <Loader />
    }

    if (patient.isError) {
        return <div>{patient.error.message}</div>
    }

    if (!patient.isSuccess) {
        return null
    }

    return (
        <ListLayout title="Karta pacjenta" id={id} tabs={tabs}>
            <PatientStats data={patient.data.data} />
        </ListLayout>
    )
}

type PatientStatsProps = {
    data: PatientResponse
}

function PatientStats({ data }: PatientStatsProps) {
    const navigate = useNavigate()

    const handleNothing = useCallback(() => {}, [])

    const completedSurgeries = useMemo(
        () =>
            (data.operations || []).filter(
                (item) => item.status !== 'rejected'
            ),
        [data.operations]
    )

    const rejectedSurgeries = useMemo(
        () =>
            (data.operations || []).filter(
                (item) => item.status === 'rejected'
            ),
        [data.operations]
    )

    const completedTotalCost = useMemo(
        () =>
            completedSurgeries.reduce((acc, item) => acc + item.total_cost, 0),
        [completedSurgeries]
    )
    const rejectedTotalCost = useMemo(
        () => rejectedSurgeries.reduce((acc, item) => acc + item.total_cost, 0),
        [rejectedSurgeries]
    )

    return (
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <div
                className="flex-auto overflow-hidden rounded-md bg-white text-sm leading-6 ring-1 ring-gray-200"
                style={{
                    boxShadow:
                        '0px 1px 2px 0px #0000000F, 0px 1px 3px 0px #0000001A',
                }}
            >
                <div className="flex items-center justify-between p-6">
                    <div className="flex-auto">
                        <div className="text-sm leading-5 font-medium text-gray-600">
                            Pacjent,
                        </div>
                        <div className="text-2xl leading-8 font-bold text-gray-900">
                            {displayPatientName({
                                first_name: data.first_name,
                                last_name: data.last_name,
                            })}{' '}
                            ({data.age ? data.age : '-'})
                        </div>
                    </div>
                    <div className="flex items-center">
                        <Toggle
                            checked={data.is_child}
                            disabled={true}
                            handleChange={handleNothing}
                        />
                        <Label className="pl-2 -mb-0">Dziecko</Label>
                    </div>
                </div>
                <div className="grid grid-cols-3 border-t border-gray-200 divide-x divide-gray-200 bg-gray-50">
                    <div className="flex items-center gap-x-2.5 px-6 py-4 font-semibold text-gray-900">
                        <span className="text-base leading-6 font-normal text-gray-500">
                            Telefon:
                        </span>
                        <span className="text-lg leading-7 font-medium text-gray-700 overflow-auto">
                            {data.phone
                                ? phoneWithoutPolandPrefix(data.phone)
                                : '-'}
                            {data.phone_note && (
                                <div className="text-sm leading-tight font-normal text-gray-400 truncate">
                                    {data.phone_note}
                                </div>
                            )}
                        </span>
                    </div>
                    <div className="flex items-center gap-x-2.5 px-6 py-4 font-semibold text-gray-900">
                        <span className="text-base leading-6 font-normal text-gray-500">
                            PESEL:
                        </span>
                        <span className="text-lg leading-7 font-medium text-gray-700">
                            {data.pesel || '-'}
                        </span>
                    </div>
                    <div className="flex items-center gap-x-2.5 px-6 py-4 font-semibold text-gray-900">
                        <span className="text-base leading-6 font-normal text-gray-500">
                            Data urodzenia:
                        </span>
                        <span className="text-lg leading-7 font-medium text-gray-700">
                            {data.date_of_birth
                                ? moment(data.date_of_birth).format(
                                      'DD.MM.YYYY'
                                  )
                                : ''}
                        </span>
                    </div>
                </div>
            </div>

            <div className="rounded-md bg-white py-6 px-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                    Podsumowanie
                </h2>

                <div className="mt-5 mb-10 grid grid-cols-1 gap-5 sm:grid-cols-2">
                    <div>
                        <BorderedItem>
                            <Item
                                title="Wykonane zabiegi"
                                icon={
                                    <ItemIcon
                                        icon={
                                            <IconMedicine75 className="w-5 h-5 text-white" />
                                        }
                                        bgOuter="bg-blue-50"
                                        bgInner="bg-blue-800"
                                        disabledBgOuter="bg-gray-50"
                                        disabledBgInner="bg-gray-200"
                                    />
                                }
                                left={
                                    <ItemValue>
                                        {completedSurgeries.length}
                                    </ItemValue>
                                }
                                right={
                                    <ItemLabelledValue label="Suma:">
                                        <ItemValue>
                                            {roundedPrice(completedTotalCost)}
                                        </ItemValue>
                                    </ItemLabelledValue>
                                }
                                content={
                                    completedSurgeries.length > 0 && (
                                        <div className="pt-1 border-t border-gray-200">
                                            <div className="py-1 space-y-1">
                                                {completedSurgeries.map(
                                                    (item, index) => (
                                                        <div
                                                            key={item.id}
                                                            className="flex justify-between leading-5"
                                                        >
                                                            <div className="flex-auto text-sm font-normal text-gray-700">
                                                                {
                                                                    item
                                                                        .procedure
                                                                        .name
                                                                }
                                                            </div>
                                                            <div className="flex pt-0.5 text-xs font-normal text-gray-600">
                                                                <span className="text-gray-400">
                                                                    Data:
                                                                </span>
                                                                <span className="pl-1">
                                                                    {moment(
                                                                        item.final_operation_date
                                                                    ).format(
                                                                        'DD.MM.YYYY'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            <div>
                                                <Button
                                                    type="button"
                                                    variant="default"
                                                    size="sm"
                                                    onClick={() =>
                                                        navigate(
                                                            `/patients/${data.id}/surgeries`
                                                        )
                                                    }
                                                >
                                                    <span className="font-medium">
                                                        Wszystkie operacje
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                }
                            />
                        </BorderedItem>
                    </div>
                    <div>
                        <BorderedItem>
                            <Item
                                title="Zrzucone zabiegi"
                                icon={
                                    <ItemIcon
                                        icon={
                                            <IconMedicine89 className="w-5 h-5 text-gray-900" />
                                        }
                                        bgOuter="bg-gray-700"
                                        bgInner="bg-gray-300"
                                        disabledBgOuter="bg-gray-50"
                                        disabledBgInner="bg-gray-200"
                                        disabled={false}
                                    />
                                }
                                left={
                                    <ItemValue>
                                        {rejectedSurgeries.length}
                                    </ItemValue>
                                }
                                right={
                                    <ItemLabelledValue label="Suma:">
                                        <ItemValue>
                                            {roundedPrice(rejectedTotalCost)}
                                        </ItemValue>
                                    </ItemLabelledValue>
                                }
                                content={
                                    rejectedSurgeries.length > 0 && (
                                        <div className="pt-1 border-t border-gray-200">
                                            <div className="py-1 space-y-1">
                                                {rejectedSurgeries.map(
                                                    (item, index) => (
                                                        <div
                                                            key={item.id}
                                                            className="flex justify-between leading-5"
                                                        >
                                                            <div className="flex-auto text-sm font-normal text-gray-700">
                                                                {item.reject_reason ||
                                                                    '-'}
                                                            </div>
                                                            <div className="flex pt-0.5 text-xs font-normal text-gray-600">
                                                                <span className="text-gray-400">
                                                                    Data:
                                                                </span>
                                                                <span className="pl-1">
                                                                    {moment(
                                                                        item.estimated_date
                                                                    ).format(
                                                                        'DD.MM.YYYY'
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                            />
                        </BorderedItem>
                    </div>
                </div>
            </div>
        </div>
    )
}
