import { useQueryClient } from 'react-query'

import { useNotification } from 'hooks'
import { useUpdateOperation } from 'api'
import { useState } from 'react'

export function useOperationActions() {
    const queryClient = useQueryClient()
    const showNotification = useNotification()
    const { mutate: update } = useUpdateOperation()

    const [isRestoreMutating, setIsRestoreMutating] = useState(false)

    const onConfirmRestore = (id: number) => {
        setIsRestoreMutating(true)

        update(
            {
                id,
                data: { restored: false },
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operations'])
                    showNotification({
                        content: 'Zmiana została zapisana',
                        type: 'success',
                    })
                    setIsRestoreMutating(false)
                },
                onError: () => {
                    setIsRestoreMutating(false)
                    showNotification({
                        content: 'Nie udało się zapisać zmiany',
                        type: 'danger',
                    })
                },
            }
        )
    }

    return {
        isRestoreMutating,
        onConfirmRestore,
    }
}
