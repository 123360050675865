import {
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import axios from 'api/axios'

import type {
    Operation,
    OperationItem,
    ResponseList,
    ResponseError,
    ResponseData,
    PostOperation,
    OperationResponse,
    Pagination,
    SurgicalTeamMemberInput,
    OperationTimes,
} from 'api/types'
import type { SurgeryForm, SurgeryDraftForm } from 'types'
import type { PostOperationDraft } from 'api/types'
import type { OperationRenewForm } from 'types/OperationRenewForm'
import type { OperationRenewModalState } from 'components/OperationRenewModal'
import client from '../axios'

export const getOperations = <T>(filters?: {}): Promise<T> =>
    axios.get('/operations' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useGetOperations = <
    T = ResponseList<
        OperationItem[],
        Pagination & { expired_operations: boolean }
    >
>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        ['operations', 'index', filters],
        () => getOperations<T>(filters),
        options
    )

export const useInfiniteOperations = <
    T = ResponseList<
        OperationItem[],
        Pagination & { expired_operations: boolean }
    >
>(
    filters?: {},
    options?: UseInfiniteQueryOptions<T, ResponseError>
): UseInfiniteQueryResult<T, ResponseError> =>
    useInfiniteQuery<T, ResponseError>(
        ['operations', 'index', filters],
        ({ pageParam = 1 }) =>
            getOperations<T>({ ...filters, page: pageParam }),
        options
    )

export const useGetOperation = <
    T extends OperationResponse,
    E extends ResponseError
>(
    id?: number | string,
    options?: UseQueryOptions<ResponseData<T>, E>
): UseQueryResult<ResponseData<T>, E> =>
    useQuery<ResponseData<T>, E>(
        ['operations', id],
        () => axios.get(`/operations/${id}`),
        options
    )

export const createOperation = <R1, R2>(data: R1): Promise<R2> =>
    axios.post('/operations', data)

export const useCreateOperation = <
    R1 = PostOperation,
    R2 = ResponseData<Operation>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => createOperation<R1, R2>(data))

export const updateOperation = <R1, R2>(
    id: number | string,
    data: R1
): Promise<R2> => axios.patch('/operations/' + id, data)

export const useUpdateOperation = <
    R1 extends {
        id: number | string
        data: Partial<PostOperation>
    },
    R2 = ResponseData<Operation>
>(
    options?: UseMutationOptions<R2, ResponseError, R1>
): UseMutationResult<R2, ResponseError, R1> =>
    useMutation({
        ...options,
        mutationFn: (data) =>
            updateOperation<R1['data'], R2>(data.id, data.data),
    })

export const deleteOperation = <R>(id: number): Promise<R> =>
    axios.delete(`/operations/${id}`)

export const useDeleteOperation = <R = Operation>(): UseMutationResult<
    R,
    ResponseError,
    number
> => useMutation((id: number) => deleteOperation<R>(id))

export const useRenewOperation = <
    R1 extends {
        id: OperationRenewModalState['id']
        data: OperationRenewForm
    },
    R2 = ResponseData<Operation>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) =>
        axios.patch('/operations/' + data.id + '/renew', data.data)
    )

export const useGetOperationTimes = <
    T extends OperationTimes,
    E extends ResponseError
>(
    filters: { date: string },
    options?: UseQueryOptions<ResponseData<T[]>, E>
): UseQueryResult<ResponseData<T[]>, E> =>
    useQuery<ResponseData<T[]>, E>(
        ['operations-times', filters.date],
        () =>
            axios.get(
                `/operations/times` +
                    (filters ? '?' + qs.stringify(filters, {}) : '')
            ),
        options
    )

export const transformSurgeryFormToApi: (
    values: SurgeryForm
) => Partial<PostOperation> = (values) => {
    return Object.keys(values)
        .filter((key) => !key.startsWith('_'))
        .filter((key) => !['doctor', 'assistant'].includes(key))
        .reduce((acc, key) => {
            if (key === 'patient' || key === 'procedure') {
                if (values[key]) {
                    acc[key] = values[key]!.id as number
                } else {
                    acc[key] = null
                }

                return acc
            }

            if (
                key === 'preferred_beginning_hour' ||
                key === 'estimated_duration' ||
                key === 'anesthesia_type'
            ) {
                if (values[key]) {
                    acc[key] = values[key]!.id as string
                }

                return acc
            }

            if (key === 'implants') {
                if (values.contains_implants) {
                    acc['implants'] = values[key]
                } else {
                    acc['implants'] = []
                }

                return acc
            }

            if (key === 'implants_used') {
                if (values.contains_implants_used) {
                    acc['implants_used'] = values[key]
                } else {
                    acc['implants_used'] = []
                }

                return acc
            }

            if (key === 'payer') {
                acc[key] = values[key] ? (values[key]!.id as number) : null

                return acc
            }

            if (key === 'surgical_team') {
                const doctor: SurgicalTeamMemberInput[] = []

                if (values.doctor) {
                    doctor.push({ id: values.doctor.id, role: 'doctor' })
                }

                acc[key] = [
                    ...doctor,
                    ...values.assistant
                        .filter((item) => !!item)
                        .map((item) => ({
                            id: item!.id,
                            role: 'assistant',
                        })),
                    ...values.surgical_team.filter(
                        (item) => !['doctor', 'assistant'].includes(item.role)
                    ),
                ].filter((item) => !!item)

                return acc
            }

            acc = {
                ...acc,
                [key]: values[key as keyof SurgeryForm],
            }

            return acc
        }, {} as Partial<PostOperation>)
}

export const transformSurgeryDraftFormToApi: (
    values: SurgeryDraftForm & { status: Operation['status'] }
) => Partial<PostOperationDraft> = (values) => {
    return Object.keys(values)
        .filter((key) => !key.startsWith('_'))
        .reduce((acc, key) => {
            if (key === 'preferred_beginning_hour') {
                if (values[key]) {
                    acc[key] = values[key]!.id as string
                }

                return acc
            }

            if (key === 'implants') {
                if (values.contains_implants) {
                    acc['implants'] = values[key]
                } else {
                    acc['implants'] = []
                }

                return acc
            }

            acc = {
                ...acc,
                [key]: values[key as keyof SurgeryDraftForm],
            }

            return acc
        }, {} as Partial<PostOperationDraft>)
}

export const exportOperationList = (filters?: {}) => {
    return axios.get(
        '/operations?export=true' +
            (filters ? '&' + qs.stringify(filters, {}) : ''),
        {
            headers: {
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'blob',
        }
    )
}

export const exportSurgeryPerformedList = (filters?: {}) => {
    return client.post(
        '/operations/implants-export',
        {},
        {
            params: filters,
            headers: {
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'blob',
        }
    )
}
