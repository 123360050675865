import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FieldArray, Form, Formik, useFormikContext } from 'formik'
import { Link } from 'react-router-dom'
import moment from 'moment'
import classNames from 'classnames'
import { ClockIcon } from '@heroicons/react/outline'
import { PlusIcon, ThumbUpIcon } from '@heroicons/react/solid'

import {
    formatCurrency,
    getDurationArray,
    isPatientName,
    displayPatientName,
    timeEvery15minutesPeriodGenerator,
    displayAdmissionDate,
    getSurgicalTeamRoleLabel,
    formatTime,
    formatDuration,
} from 'helpers'
import {
    ANESTHESIA_TYPES,
    OPERATION_ALLOWED_STATUSES_TO_CHANGE,
    OPERATION_STATUS_CANCELED,
    OPERATION_STATUS_REJECTED,
} from 'constants/index'
import { toScheduleOperationFormValues, useSendScheduleUpdateSMS } from 'api'
import alertContext from 'contexts/alerts'
import {
    useChildToggle,
    usePolicy,
    useScheduleSMSPolicy,
    useCaregiverPanelPolicy,
    useSchedulePlannerPolicyGroup,
    useModal,
} from 'hooks'
import { Button, InputText, Label, Toggle } from 'components/ui'
import {
    ErrorMessage,
    AvailableDatePickerField,
    DropdownField,
    DropdownItem,
    EmployeePickerField,
    EquipmentField,
    EquipmentList,
    TextAreaField,
    TextField,
} from 'components/forms'
import Comment from 'components/Comment'
import ImplantsHistoryModal from 'components/ImplantsHistoryModal'
import ScheduleOperationStatusContainer from 'containers/ScheduleOperationStatus'
import ScheduleOperationPlan from 'containers/ScheduleOperationFormPlan'
import ScheduleOperationStatusCancelled from 'components/ScheduleOperationStatusCancelled'
import UserAvatar from 'components/UserAvatar'
import CaregiverActions from 'components/ScheduleOperationCaregiverActions'

import type {
    Comment as IComment,
    OperatingRoom,
    ScheduleOperation as TScheduleOperation,
} from 'api/types'
import type {
    FormSubmitFn,
    ScheduleOperationForm as TScheduleOperationForm,
} from 'types'
import type { OperationRenewModalState } from 'components/OperationRenewModal'
import { useOperationDurationsPolicy } from '../hooks/policies/useOperationDurationsPolicy'
import OperationDurations from './OperationDurations'

const ScheduleOperationForm: React.FC<{
    editable: boolean
    id: number
    data: TScheduleOperation
    rooms: OperatingRoom[]
    comments?: IComment[]
    handleSubmit: FormSubmitFn<TScheduleOperationForm>
    handleClose: () => void
    handleChildToggleChange: ReturnType<typeof useChildToggle>
    isRejecting?: boolean
    isRenewing?: boolean
    onReject?: () => void
    onRenew?: (data: OperationRenewModalState) => void
    onCancel?: (id: number) => void
    hideSMSButton?: boolean
}> = ({
    editable,
    id,
    data,
    rooms,
    comments,
    isRejecting,
    isRenewing,
    onReject,
    onRenew,
    handleSubmit,
    handleChildToggleChange,
    onCancel,
    handleClose,
    hideSMSButton,
}) => {
    const { add: alert } = useContext(alertContext)

    const { user } = usePolicy()
    const scheduleSMSPolicy = useScheduleSMSPolicy()
    const caregiverPanelPolicy = useCaregiverPanelPolicy()
    const schedulePlannerPolicy = useSchedulePlannerPolicyGroup()
    const operationDurationsPolicy = useOperationDurationsPolicy()

    const implantsHistoryModal = useModal(false)
    const [implantsHistoryOperationId, setImplantsHistoryOperationId] =
        useState<number | undefined>()

    useEffect(() => {
        implantsHistoryOperationId && implantsHistoryModal.openModal()
    }, [implantsHistoryOperationId]) // eslint-disable-line

    const hours: DropdownItem[] = useMemo(
        () =>
            timeEvery15minutesPeriodGenerator().map((minutes) => ({
                id: minutes + ':00', // add seconds after minutes
                name: minutes,
            })),
        []
    )

    const initialValues = useMemo(
        () => toScheduleOperationFormValues({ data, rooms }),
        [data, rooms]
    )

    const isCancelled = useMemo(
        () => data.status === OPERATION_STATUS_CANCELED,
        [data.status]
    )

    const canBeMoved = useMemo(
        () =>
            OPERATION_ALLOWED_STATUSES_TO_CHANGE.includes(data.status) &&
            (data.events.length === 0 ||
                (data.events.length === 1 &&
                    data.events[0].status === 'awaiting_for_call')),
        [data]
    )

    const isNotStarted = useMemo(() => {
        return (
            data.events.length === 0 ||
            (data.events.length === 1 &&
                [
                    'awaiting_for_call',
                    'calling_for_patient',
                    'in_transfer_to_block',
                    'admitted_to_operating_block',
                ].includes(data.events[0].status))
        )
    }, [data])

    const canBeRejected = useMemo(
        () =>
            isNotStarted &&
            OPERATION_ALLOWED_STATUSES_TO_CHANGE.includes(data.status) &&
            OPERATION_STATUS_REJECTED !== data.status,
        [isNotStarted, data]
    )

    const canReject = useMemo(
        () => schedulePlannerPolicy.canReject && canBeRejected,
        [schedulePlannerPolicy.canReject, canBeRejected]
    )

    const canRenew = !!onRenew && schedulePlannerPolicy.canRenew

    const patientName = useMemo(() => {
        return isPatientName(data.patient)
            ? displayPatientName(data.patient)
            : '-'
    }, [data.patient])

    const anesthesiaType = useMemo(
        () => ANESTHESIA_TYPES.find((item) => item.id === data.anesthesia_type),
        [data.anesthesia_type]
    )

    const { mutateAsync: sendSMS, isLoading: isSendingSMS } =
        useSendScheduleUpdateSMS({
            onSuccess: async () => {
                alert({
                    content:
                        'SMSy z aktualizacją planu operacyjnego zostały wysłane',
                    type: 'success',
                })
            },
            onError: () => {
                alert({
                    content: 'Podczas wysyłania SMS-ów wystąpił błąd',
                    type: 'danger',
                })
            },
        })

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                validateOnChange={false}
            >
                {({
                    isSubmitting,
                    values,
                    errors,
                    setFieldValue,
                    submitForm,
                }) => (
                    <ScheduleOperationPlan
                        id={id}
                        rooms={rooms}
                        hours={hours}
                        enabled={editable && canBeMoved}
                    >
                        {({ filteredRooms, filteredHours }) => (
                            <div className="min-h-0 flex-1 flex flex-col">
                                {isCancelled && (
                                    <div className="flex divide-x divide-gray-300 relative">
                                        <ScheduleOperationStatusCancelled />
                                    </div>
                                )}
                                {!isCancelled && data.events.length > 0 && (
                                    <div className="flex divide-x divide-gray-300 relative">
                                        {data.events.map((event) => (
                                            <ScheduleOperationStatusContainer
                                                key={event.id}
                                                editable={editable}
                                                isFullWidth={
                                                    data.events.length === 1
                                                }
                                                event={event}
                                                procedureType={
                                                    data.procedure_type
                                                }
                                                operationId={data.id}
                                                restoreStatuses={
                                                    data.restore_statuses
                                                }
                                            />
                                        ))}
                                    </div>
                                )}
                                <div className="min-h-0 flex-1 flex flex-col overflow-y-scroll">
                                    <div className="flex flex-col px-4 sm:px-8 pt-4 space-between text-gray-900">
                                        <div className="flex flex-col-reverse md:flex-row md:items-center md:justify-between">
                                            <span className="text-2xl leading-8 text-gray-800">
                                                <span className="font-medium">
                                                    {caregiverPanelPolicy.canIndex ? (
                                                        <Link
                                                            to="/caregiver"
                                                            state={{
                                                                id: data.id,
                                                            }}
                                                            className="hover:text-blue-500"
                                                        >
                                                            {patientName}
                                                        </Link>
                                                    ) : (
                                                        patientName
                                                    )}
                                                </span>
                                                <span className="pl-5 text-gray-500">
                                                    (
                                                    {data.patient.age ||
                                                    data.patient.age === 0
                                                        ? data.patient.age
                                                        : '-'}
                                                    )
                                                </span>
                                            </span>
                                            <div className="flex flex-col items-end">
                                                <div className="text-sm leading-5 mt-2 md:mt-0 text-gray-400">
                                                    Szacowany czas
                                                </div>
                                                <div className="flex items-center justify-end text-gray-800 text-base leading-6 font-medium">
                                                    <ClockIcon className="inline-flex mr-2 w-5 h-5" />
                                                    <span>
                                                        {moment()
                                                            .startOf('day')
                                                            .add(
                                                                data.duration_without_cleaning,
                                                                'minutes'
                                                            )
                                                            .format('HH:mm')}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my-5 flex">
                                        <div className="w-8/12 flex-1 flex flex-col px-4 sm:px-8 space-between text-gray-900">
                                            <div>
                                                <div className="flex items-center justify-between">
                                                    <div className="text-base leading-6 text-gray-900 font-medium">
                                                        Planowane
                                                    </div>
                                                    <div className="flex items-center justify-between">
                                                        <Toggle
                                                            checked={
                                                                !!data.additional_bed
                                                            }
                                                            disabled={
                                                                isCancelled ||
                                                                !editable
                                                            }
                                                            handleChange={
                                                                handleChildToggleChange
                                                            }
                                                        />
                                                        <Label className="ml-3 -mb-0">
                                                            Dziecko
                                                        </Label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                {editable &&
                                                    (user.isSuperAdmin ||
                                                        user.isAdmin ||
                                                        user.isPlanner) && (
                                                        <>
                                                            <div className="mb-2">
                                                                <div className="flex items-center space-x-2">
                                                                    <div
                                                                        style={{
                                                                            flexBasis:
                                                                                '33.333333%',
                                                                        }}
                                                                    >
                                                                        <ApproveOperationField
                                                                            disabled={
                                                                                isCancelled
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="flex-grow">
                                                                        <ApproveImplantsField
                                                                            disabled={
                                                                                isCancelled
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="py-4 px-2 bg-gray-100 rounded-md">
                                                                <div className="grid grid-cols-2 gap-y-4 gap-x-6">
                                                                    <div className="grid-col-1">
                                                                        <Label>
                                                                            Dzień
                                                                            operacji
                                                                        </Label>
                                                                        <AvailableDatePickerField
                                                                            name="estimated_date"
                                                                            placeholder="DD.MM.RRRR"
                                                                            disabled={
                                                                                !canBeMoved
                                                                            }
                                                                            disabledBefore={moment().toDate()}
                                                                            onChange={() => {
                                                                                setFieldValue(
                                                                                    'operating_room',
                                                                                    undefined
                                                                                )
                                                                                setFieldValue(
                                                                                    'start_at',
                                                                                    undefined
                                                                                )
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="grid-col-2">
                                                                        <Label>
                                                                            Sala
                                                                            operacyjna
                                                                        </Label>
                                                                        <DropdownField
                                                                            name="operating_room"
                                                                            placeholder="Wybierz salę"
                                                                            disabled={
                                                                                !canBeMoved
                                                                            }
                                                                            items={
                                                                                filteredRooms
                                                                            }
                                                                            bulkOption={
                                                                                true
                                                                            }
                                                                            bulkOptionName="&nbsp;"
                                                                            handleChange={(
                                                                                value
                                                                            ) => {
                                                                                setFieldValue(
                                                                                    'start_at',
                                                                                    undefined
                                                                                )
                                                                                setFieldValue(
                                                                                    'doctor_starts_at',
                                                                                    undefined
                                                                                )
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="grid-col-1">
                                                                        <Label>
                                                                            Czas
                                                                            rozpoczęcia
                                                                            operacji
                                                                        </Label>
                                                                        <DropdownField
                                                                            name="start_at"
                                                                            placeholder={
                                                                                !!values.operating_room
                                                                                    ? 'Wybierz godzinę'
                                                                                    : ''
                                                                            }
                                                                            items={
                                                                                filteredHours
                                                                            }
                                                                            hasError={
                                                                                values.start_at &&
                                                                                filteredHours.length >
                                                                                    0
                                                                                    ? !filteredHours.find(
                                                                                          (
                                                                                              item
                                                                                          ) =>
                                                                                              item.id ===
                                                                                              values.start_at!
                                                                                                  .id
                                                                                      )
                                                                                    : false
                                                                            }
                                                                            disabled={
                                                                                !canBeMoved ||
                                                                                !values.operating_room
                                                                            }
                                                                            handleChange={(
                                                                                value
                                                                            ) => {
                                                                                setFieldValue(
                                                                                    'doctor_starts_at',
                                                                                    undefined
                                                                                )
                                                                            }}
                                                                            bulkOption={
                                                                                true
                                                                            }
                                                                            bulkOptionName="&nbsp;"
                                                                        />
                                                                    </div>
                                                                    <div className="grid-col-2">
                                                                        <Label>
                                                                            Czas
                                                                            rozpoczęcia
                                                                            lekarza
                                                                        </Label>
                                                                        <DropdownField
                                                                            name="doctor_starts_at"
                                                                            placeholder={
                                                                                !!values.start_at
                                                                                    ? 'Wybierz godzinę'
                                                                                    : ''
                                                                            }
                                                                            items={
                                                                                hours
                                                                            }
                                                                            disabled={
                                                                                !canBeMoved ||
                                                                                !values.operating_room ||
                                                                                !values.start_at
                                                                            }
                                                                            bulkOption={
                                                                                true
                                                                            }
                                                                            bulkOptionName="&nbsp;"
                                                                        />
                                                                    </div>
                                                                    <div className="grid-col-1">
                                                                        <Label>
                                                                            Czas
                                                                            zakończenia
                                                                            operacji
                                                                        </Label>
                                                                        <InputText
                                                                            value={
                                                                                initialValues.finish_at ||
                                                                                '-'
                                                                            }
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="grid-col-2">
                                                                        <Label>
                                                                            Przewidywany
                                                                            czas
                                                                            trwania
                                                                            operacji
                                                                        </Label>
                                                                        <DropdownField
                                                                            name="estimated_duration"
                                                                            items={getDurationArray(
                                                                                12,
                                                                                15
                                                                            )}
                                                                            disabled={
                                                                                !canBeMoved ||
                                                                                !!values.operating_room ||
                                                                                !!values.start_at
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                {(!editable ||
                                                    (editable &&
                                                        !(
                                                            user.isSuperAdmin ||
                                                            user.isAdmin ||
                                                            user.isPlanner
                                                        ))) && (
                                                    <>
                                                        <div className="mt-2">
                                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 border-b border-gray-200">
                                                                <div className="grid-col-1">
                                                                    <dl>
                                                                        <div className="py-3 flex items-center border-t border-gray-200">
                                                                            <dt className="flex-1 text-sm text-gray-500">
                                                                                Czas
                                                                                rozpoczęcia
                                                                                operacji
                                                                            </dt>
                                                                            <dd className="flex text-sm text-gray-900 font-medium">
                                                                                {values
                                                                                    .start_at
                                                                                    ?.name ||
                                                                                    '-'}
                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </div>
                                                                <div className="grid-col-2">
                                                                    <dl>
                                                                        <div className="py-3 flex items-center border-t border-gray-200">
                                                                            <dt className="flex-1 text-sm text-gray-500">
                                                                                Czas
                                                                                rozpoczęcia
                                                                                lekarza
                                                                            </dt>
                                                                            <dd className="flex text-sm text-gray-900 font-medium">
                                                                                {values
                                                                                    .doctor_starts_at
                                                                                    ?.name ||
                                                                                    '-'}
                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </div>
                                                                <div className="grid-col-1">
                                                                    <dl>
                                                                        <div className="py-3 flex items-center border-t border-gray-200">
                                                                            <dt className="flex-1 text-sm text-gray-500">
                                                                                Czas
                                                                                zakończenia
                                                                                operacji
                                                                            </dt>
                                                                            <dd className="flex text-sm text-gray-900 font-medium">
                                                                                {initialValues.finish_at ||
                                                                                    '-'}
                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </div>
                                                                <div className="grid-col-2">
                                                                    <dl>
                                                                        <div className="py-3 flex items-center border-t border-gray-200">
                                                                            <dt className="flex-1 text-sm text-gray-500">
                                                                                Przewidywany
                                                                                czas
                                                                                trwania
                                                                                operacji
                                                                            </dt>
                                                                            <dd className="flex text-sm text-gray-900 font-medium">
                                                                                {data.estimated_duration ||
                                                                                    '-'}
                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                            {(user.isDoctor ||
                                                                user.isCaregiver) && (
                                                                <div className="grid grid-cols-1 gap-x-12 border-b border-gray-200">
                                                                    <div className="grid-col-1">
                                                                        <dl>
                                                                            <div className="py-3 flex items-center border-gray-200">
                                                                                <dt className="flex-1 text-sm text-gray-500">
                                                                                    Koszt
                                                                                    operacji
                                                                                </dt>
                                                                                <dd className="flex text-sm text-gray-900 font-medium">
                                                                                    {data.total_cost ||
                                                                                    data.total_cost ===
                                                                                        0
                                                                                        ? formatCurrency(
                                                                                              data.total_cost
                                                                                          )
                                                                                        : '-'}
                                                                                </dd>
                                                                            </div>
                                                                        </dl>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="mt-3">
                                                            <div className="text-base leading-6 text-gray-900 font-medium">
                                                                Wykonane
                                                            </div>
                                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 border-b border-gray-200 mt-3">
                                                                <div className="grid-col-1">
                                                                    <dl>
                                                                        <div className="py-3 flex items-center border-t border-gray-200">
                                                                            <dt className="flex-1 text-sm text-gray-500">
                                                                                Czas
                                                                                rozpoczęcia
                                                                                operacji
                                                                            </dt>
                                                                            <dd className="flex text-sm text-gray-900 font-medium">
                                                                                {data.real_operation_start
                                                                                    ? formatTime(
                                                                                          data.real_operation_start
                                                                                      )
                                                                                    : '-'}
                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </div>
                                                                <div className="grid-col-2">
                                                                    <dl>
                                                                        <div className="py-3 flex items-center border-t border-gray-200">
                                                                            <dt className="flex-1 text-sm text-gray-500">
                                                                                Czas
                                                                                zabiegu
                                                                            </dt>
                                                                            <dd className="flex text-sm text-gray-900 font-medium">
                                                                                {data.real_operation_start &&
                                                                                data.real_operation_end
                                                                                    ? formatDuration(
                                                                                          moment(
                                                                                              data.real_operation_start
                                                                                          ),
                                                                                          moment(
                                                                                              data.real_operation_end
                                                                                          )
                                                                                      )
                                                                                    : '-'}
                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </div>
                                                                <div className="grid-col-1">
                                                                    <dl>
                                                                        <div className="py-3 flex items-center border-t border-gray-200">
                                                                            <dt className="flex-1 text-sm text-gray-500">
                                                                                Czas
                                                                                zakończenia
                                                                                operacji
                                                                            </dt>
                                                                            <dd className="flex text-sm text-gray-900 font-medium">
                                                                                {data.real_operation_end
                                                                                    ? formatTime(
                                                                                          data.real_operation_end
                                                                                      )
                                                                                    : '-'}
                                                                            </dd>
                                                                        </div>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {editable && user.isCaregiver && (
                                                    <div className="mt-4 p-4 bg-gray-100 rounded-md">
                                                        <div className="mb-6 flex items-center justify-between">
                                                            <span className="text-base leading-6 text-gray-900 font-medium">
                                                                Działania
                                                            </span>
                                                        </div>
                                                        <CaregiverActions
                                                            disabled={
                                                                isCancelled ||
                                                                !canBeMoved
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mt-4 flex items-center justify-between">
                                                <span className="text-base leading-6 text-gray-900 font-medium">
                                                    Uwagi do zabiegu
                                                </span>
                                            </div>
                                            <div className="mt-2">
                                                <dl>
                                                    <div className="py-3 md:grid md:grid-cols-3 md:gap-1">
                                                        <dt className="text-sm text-gray-500">
                                                            Rodzaj zabiegu
                                                        </dt>
                                                        <dd className="mt-1 flex sm:mt-0 sm:col-span-2">
                                                            <span className="text-sm text-gray-900 leading-5 font-medium">
                                                                {
                                                                    data
                                                                        .procedure_type
                                                                        .name
                                                                }
                                                            </span>
                                                        </dd>
                                                    </div>
                                                    <div className="py-3 md:grid md:grid-cols-3 md:gap-1">
                                                        <dt className="text-sm text-gray-500">
                                                            Operacja
                                                        </dt>
                                                        <dd className="mt-1 flex sm:mt-0 sm:col-span-2">
                                                            <span className="text-sm text-gray-900 leading-5 font-medium">
                                                                {data.name}
                                                            </span>
                                                        </dd>
                                                    </div>
                                                    <div className="py-3 md:grid md:grid-cols-3 md:gap-1">
                                                        <dt className="text-sm text-gray-500">
                                                            ICD-9
                                                        </dt>
                                                        <dd className="mt-1 flex sm:mt-0 sm:col-span-2">
                                                            <span className="text-sm text-gray-900 leading-5 font-medium">
                                                                {data.icd9
                                                                    ? data.icd9
                                                                          ?.code +
                                                                      ' - ' +
                                                                      data.icd9
                                                                          .description
                                                                    : '-'}
                                                            </span>
                                                        </dd>
                                                    </div>
                                                    <div className="py-3 md:grid md:grid-cols-3 md:gap-1">
                                                        <dt className="text-sm text-gray-500">
                                                            Rozpoznanie
                                                        </dt>
                                                        <dd className="mt-1 flex sm:mt-0 sm:col-span-2">
                                                            <span className="text-sm text-gray-900 leading-5 font-medium whitespace-pre-line">
                                                                {data.diagnosis}
                                                            </span>
                                                        </dd>
                                                    </div>
                                                    <div className="py-3 md:grid md:grid-cols-3 md:gap-1">
                                                        <dt className="text-sm text-gray-500">
                                                            Znieczulenie
                                                        </dt>
                                                        <dd className="mt-1 flex sm:mt-0 sm:col-span-2">
                                                            <div className="text-sm text-gray-900 leading-5 font-medium whitespace-pre-line">
                                                                {!!anesthesiaType
                                                                    ? [
                                                                          [
                                                                              anesthesiaType.name,
                                                                              data.anesthesia_info,
                                                                          ]
                                                                              .filter(
                                                                                  Boolean
                                                                              )
                                                                              .join(
                                                                                  '\n'
                                                                              ),
                                                                          data.weight
                                                                              ? `Waga: ${data.weight} kg`
                                                                              : null,
                                                                      ]
                                                                          .filter(
                                                                              Boolean
                                                                          )
                                                                          .join(
                                                                              '\n'
                                                                          )
                                                                    : '-'}
                                                            </div>
                                                        </dd>
                                                    </div>
                                                    <div className="py-3 md:grid md:grid-cols-3 md:gap-1">
                                                        <dt className="text-sm text-gray-500">
                                                            Przyjęcie
                                                        </dt>
                                                        <dd className="mt-1 flex align-center sm:mt-0 sm:col-span-2">
                                                            {!!data.patient_informed && (
                                                                <ThumbUpIcon className="mr-2 w-4 text-green-500" />
                                                            )}
                                                            <span className="text-sm text-gray-900 leading-5 font-medium">
                                                                {!data.admission_date &&
                                                                    '-'}
                                                                {!!data.admission_date &&
                                                                    displayAdmissionDate(
                                                                        data.admission_date,
                                                                        data.estimated_date
                                                                    )}
                                                            </span>
                                                        </dd>
                                                    </div>
                                                    <div className="py-3 md:grid md:grid-cols-3 md:gap-1">
                                                        <dt className="text-sm text-gray-500">
                                                            Dieta
                                                        </dt>
                                                        <dd className="mt-1 flex sm:mt-0 sm:col-span-2">
                                                            <span className="text-sm text-gray-900 leading-5 font-medium whitespace-pre-line">
                                                                {data.diet_info ||
                                                                    '-'}
                                                            </span>
                                                        </dd>
                                                    </div>
                                                    <div className="py-3 md:grid md:grid-cols-3 md:gap-1">
                                                        <dt className="text-sm text-gray-500">
                                                            Uwagi do zabiegu
                                                        </dt>
                                                        <dd className="mt-1 sm:mt-0 sm:col-span-2">
                                                            {editable ? (
                                                                <TextAreaField
                                                                    name="description"
                                                                    rows={4}
                                                                    disabled={
                                                                        isCancelled ||
                                                                        !(
                                                                            user.isSuperAdmin ||
                                                                            user.isAdmin ||
                                                                            user.isPlanner ||
                                                                            user.isCaregiver
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                <span className="text-sm text-gray-900 leading-5 font-medium whitespace-pre-line">
                                                                    {data.description ||
                                                                        '-'}
                                                                </span>
                                                            )}
                                                        </dd>
                                                    </div>
                                                    <div className="py-3 md:grid md:grid-cols-3 md:gap-1">
                                                        <dt className="flex items-center text-sm whitespace-nowrap text-gray-500">
                                                            Numer pokoju
                                                        </dt>
                                                        <dd className="mt-1 flex sm:mt-0 sm:col-span-2">
                                                            {!editable && (
                                                                <span className="text-sm text-gray-900 leading-5 font-medium">
                                                                    {data.room ||
                                                                        '-'}
                                                                </span>
                                                            )}
                                                            {editable && (
                                                                <div>
                                                                    <div className="w-20">
                                                                        <TextField
                                                                            name="room"
                                                                            placeholder="Numer"
                                                                            disabled={
                                                                                isCancelled
                                                                            }
                                                                            errorMessage={
                                                                                false
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <ErrorMessage name="room" />
                                                                </div>
                                                            )}
                                                        </dd>
                                                    </div>
                                                    <div className="py-3 md:grid md:grid-cols-3 md:gap-1">
                                                        <dt className="text-sm text-gray-500">
                                                            Uwagi opiekunki
                                                        </dt>
                                                        <dd className="mt-1 flex sm:mt-0 sm:col-span-2">
                                                            <span className="text-sm text-gray-900 leading-5 font-medium whitespace-pre-line">
                                                                {data.caregivers_comments ||
                                                                    '-'}
                                                            </span>
                                                        </dd>
                                                    </div>
                                                </dl>
                                            </div>
                                            <div className="mt-4 flex items-center justify-between">
                                                <span className="text-base leading-6 text-gray-900 font-medium">
                                                    Zamgławianie
                                                </span>
                                            </div>
                                            <div className="mt-6">
                                                <span
                                                    className={classNames(
                                                        'flex items-center',
                                                        {
                                                            'pointer-events-none':
                                                                !editable,
                                                        }
                                                    )}
                                                >
                                                    <input
                                                        id="fogging"
                                                        type="checkbox"
                                                        name="fogging"
                                                        className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
                                                        checked={values.fogging}
                                                        onChange={
                                                            editable
                                                                ? () => {
                                                                      setFieldValue(
                                                                          'fogging',
                                                                          !values.fogging
                                                                      )
                                                                  }
                                                                : undefined
                                                        }
                                                        readOnly={!editable}
                                                    />
                                                    <label
                                                        htmlFor="fogging"
                                                        className="ml-4 block text-sm text-gray-500"
                                                    >
                                                        Zamgławianie po operacji
                                                    </label>
                                                </span>
                                            </div>
                                            <div className="mt-8 mb-2 flex items-center justify-between">
                                                <span className="text-base leading-6 text-gray-900 font-medium">
                                                    Sprzęt medyczny
                                                </span>
                                            </div>
                                            <div className="mt-2">
                                                {editable &&
                                                (user.isSuperAdmin ||
                                                    user.isAdmin ||
                                                    user.isPlanner ||
                                                    user.isCaregiver) ? (
                                                    <EquipmentField
                                                        name="equipment"
                                                        disabled={isCancelled}
                                                    />
                                                ) : !!values.equipment &&
                                                  values.equipment.length >
                                                      0 ? (
                                                    <EquipmentList
                                                        data={values.equipment}
                                                    />
                                                ) : (
                                                    <p>-</p>
                                                )}
                                            </div>
                                            <div className="mt-4 flex items-center justify-between">
                                                <span className="text-base leading-6 text-gray-900 font-medium">
                                                    Implanty
                                                </span>
                                                <Button
                                                    variant="default"
                                                    size="sm"
                                                    onClick={() =>
                                                        setImplantsHistoryOperationId(
                                                            id
                                                        )
                                                    }
                                                >
                                                    Historia implantów
                                                </Button>
                                            </div>
                                            {!editable && (
                                                <div className="mt-1.5">
                                                    <span
                                                        className={classNames(
                                                            'inline-block rounded bg-white px-2 py-0.5 leading-normal text-xs ring-1 ring-inset',
                                                            {
                                                                'text-green-700 ring-green-700':
                                                                    data.implants_approved,
                                                                'text-red-700 ring-red-700':
                                                                    !data.implants_approved,
                                                            }
                                                        )}
                                                    >
                                                        {data.implants_approved ? (
                                                            <span>
                                                                Zamówione
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                Niezamówione
                                                            </span>
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                            <div className="mt-3">
                                                {!!data.implants &&
                                                data.implants.length > 0 ? (
                                                    <div className="flex flex-wrap">
                                                        {data.implants.map(
                                                            (item, index) => (
                                                                <span
                                                                    key={index}
                                                                    className="inline-flex mr-3 mb-3 items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800"
                                                                >
                                                                    <svg
                                                                        className="-ml-0.5 mr-1.5 h-2 w-2 text-gray-400"
                                                                        fill="currentColor"
                                                                        viewBox="0 0 8 8"
                                                                    >
                                                                        <circle
                                                                            cx={
                                                                                4
                                                                            }
                                                                            cy={
                                                                                4
                                                                            }
                                                                            r={
                                                                                3
                                                                            }
                                                                        />
                                                                    </svg>
                                                                    {item.label ||
                                                                        item.name ||
                                                                        '-'}
                                                                </span>
                                                            )
                                                        )}
                                                    </div>
                                                ) : (
                                                    <p>-</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="w-4/12">
                                            <div className="flex flex-col w-100 px-4 sm:px-0 sm:pl-0 sm:pr-8 space-between">
                                                <div className="flex items-center justify-between">
                                                    <span className="text-base leading-6 text-gray-900 font-medium">
                                                        Zespół medyczny
                                                    </span>
                                                </div>
                                                {(!editable ||
                                                    !(
                                                        user.isSuperAdmin ||
                                                        user.isAdmin ||
                                                        user.isPlanner ||
                                                        user.isCaregiver
                                                    )) && (
                                                    <div className="mt-2 border-t border-gray-200">
                                                        {data.surgical_team
                                                            .length === 0 && (
                                                            <p className="py-3">
                                                                -
                                                            </p>
                                                        )}
                                                        {data.surgical_team
                                                            .length > 0 && (
                                                            <dl>
                                                                {data.surgical_team.map(
                                                                    (item) => (
                                                                        <div
                                                                            key={
                                                                                item.id
                                                                            }
                                                                            className="py-3 flex flex-col-reverse items-start md:flex-row md:items-center border-b border-gray-200"
                                                                        >
                                                                            <dt className="flex-1 text-sm text-gray-500 w-full">
                                                                                <div className="flex flex-row-reverse md:flex-row items-center w-full">
                                                                                    <div className="flex-shrink-0">
                                                                                        <div className="w-8 h-8 ml-4 md:ml-0">
                                                                                            <UserAvatar
                                                                                                variant="avatar"
                                                                                                data={
                                                                                                    item
                                                                                                }
                                                                                                size={
                                                                                                    8
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="md:ml-4 min-w-0 flex-1">
                                                                                        <p className="text-sm text-gray-900 leading-5">
                                                                                            {
                                                                                                item.first_name
                                                                                            }{' '}
                                                                                            {
                                                                                                item.last_name
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </dt>
                                                                            <dd className="flex mb-3 md:mb-0 ">
                                                                                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm bg-blue-100 text-blue-800">
                                                                                    {getSurgicalTeamRoleLabel(
                                                                                        item.role
                                                                                    )}
                                                                                </span>
                                                                            </dd>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </dl>
                                                        )}
                                                    </div>
                                                )}
                                                {editable &&
                                                    (user.isSuperAdmin ||
                                                        user.isAdmin ||
                                                        user.isPlanner ||
                                                        user.isCaregiver) && (
                                                        <>
                                                            <div className="mt-3">
                                                                <Label>
                                                                    Operator
                                                                </Label>
                                                                <EmployeePickerField
                                                                    name="doctor"
                                                                    disabled={
                                                                        isCancelled
                                                                    }
                                                                    placeholder="Szukaj / wybierz lekarza"
                                                                    functions={[
                                                                        'doctor function',
                                                                    ]}
                                                                    messageEmptyResult="Nie znaleziono lekarza."
                                                                />
                                                            </div>
                                                            <div className="mt-6">
                                                                <Label>
                                                                    Asysta
                                                                </Label>
                                                                <AssistantFieldArray
                                                                    disabled={
                                                                        isCancelled ||
                                                                        user.isCaregiver
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="mt-6">
                                                                <Label>
                                                                    Anestezjolog
                                                                </Label>
                                                                <AnesthesiologistFieldArray
                                                                    disabled={
                                                                        isCancelled ||
                                                                        user.isCaregiver
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="mt-6">
                                                                <Label>
                                                                    Pielęgniarka
                                                                    Anestezjologiczna
                                                                </Label>
                                                                <AnesthesiologistNurseFieldArray
                                                                    disabled={
                                                                        isCancelled ||
                                                                        user.isCaregiver
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="mt-6">
                                                                <Label>
                                                                    Pielęgniarka
                                                                    operacyjna
                                                                </Label>
                                                                <OperationNurseFieldArray
                                                                    disabled={
                                                                        isCancelled ||
                                                                        user.isCaregiver
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                {((comments &&
                                                    comments.length > 0) ||
                                                    !!data.cancel_reason ||
                                                    !!data.reject_reason) && (
                                                    <>
                                                        <div className="mt-4 flex items-center justify-between">
                                                            <span className="text-base leading-6 text-gray-900 font-medium">
                                                                Komentarze
                                                            </span>
                                                        </div>
                                                        <div className="pt-1 py-3 border-b border-gray-200">
                                                            {!!data.cancel_reason &&
                                                                !!data.canceled_by &&
                                                                !!data.canceled_at && (
                                                                    <Comment
                                                                        comment={{
                                                                            author: data.canceled_by,
                                                                            content:
                                                                                data.cancel_reason,
                                                                            created_at:
                                                                                data.canceled_at,
                                                                        }}
                                                                        type="schedule"
                                                                    />
                                                                )}
                                                            {!!data.reject_reason && (
                                                                <Comment
                                                                    comment={{
                                                                        content:
                                                                            data.reject_reason,
                                                                    }}
                                                                    type="schedule"
                                                                />
                                                            )}
                                                            {comments &&
                                                                comments.length >
                                                                    0 && (
                                                                    <div className="flex flex-col">
                                                                        {comments.map(
                                                                            (
                                                                                comment
                                                                            ) => (
                                                                                <Comment
                                                                                    key={
                                                                                        comment.id
                                                                                    }
                                                                                    comment={
                                                                                        comment
                                                                                    }
                                                                                    type="schedule"
                                                                                />
                                                                            )
                                                                        )}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            {operationDurationsPolicy.canSee &&
                                                !!data.durations && (
                                                    <div className="flex flex-col w-100 px-4 sm:px-0 sm:pl-0 sm:pr-8 space-between gap-2">
                                                        <div className="flex items-center justify-between mt-4">
                                                            <span className="text-base leading-6 text-gray-900 font-medium">
                                                                Statusy operacji
                                                            </span>
                                                        </div>
                                                        <OperationDurations
                                                            durations={
                                                                data.durations
                                                            }
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                {editable && (
                                    <div className="flex-shrink-0 px-6 py-4 flex justify-between shadow-inner">
                                        <div className="space-x-4">
                                            {data.status !==
                                            OPERATION_STATUS_REJECTED ? (
                                                <Button
                                                    variant="quaternary"
                                                    type="button"
                                                    loading={isRejecting}
                                                    disabled={!canReject}
                                                    onClick={onReject}
                                                >
                                                    Zrzuć operację
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="tertiary"
                                                    type="button"
                                                    loading={isRenewing}
                                                    onClick={
                                                        onRenew
                                                            ? () =>
                                                                  onRenew({
                                                                      id,
                                                                      estimated_date:
                                                                          data.estimated_date,
                                                                  })
                                                            : undefined
                                                    }
                                                    disabled={!canRenew}
                                                >
                                                    Przywróć operację
                                                </Button>
                                            )}
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <Button
                                                type="button"
                                                onClick={handleClose}
                                            >
                                                Zamknij
                                            </Button>
                                            <Form>
                                                <Button
                                                    type="submit"
                                                    variant="primary"
                                                    className="ml-4"
                                                    disabled={isCancelled}
                                                    loading={isSubmitting}
                                                >
                                                    Zapisz
                                                </Button>
                                            </Form>
                                        </div>
                                    </div>
                                )}
                                {!editable && (
                                    <div className="flex-shrink-0 px-6 py-4 flex justify-between shadow-inner">
                                        {!hideSMSButton && (
                                            <Button
                                                type="button"
                                                variant="tertiary"
                                                loading={isSendingSMS}
                                                disabled={
                                                    !scheduleSMSPolicy.canSend
                                                }
                                                onClick={() => sendSMS({ id })}
                                            >
                                                Wyslij SMS z aktualizacją
                                            </Button>
                                        )}
                                        <Button
                                            type="button"
                                            onClick={handleClose}
                                            className="ml-auto"
                                        >
                                            Zamknij
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                    </ScheduleOperationPlan>
                )}
            </Formik>

            {implantsHistoryOperationId && (
                <ImplantsHistoryModal
                    modal={implantsHistoryModal}
                    operationId={implantsHistoryOperationId}
                    onClose={() =>
                        implantsHistoryModal.closeModal(() =>
                            setImplantsHistoryOperationId(undefined)
                        )
                    }
                />
            )}
        </>
    )
}

const ApproveOperationField: React.FC<{
    disabled?: boolean
}> = ({ disabled }) => {
    const { values, setFieldValue } = useFormikContext<TScheduleOperationForm>()

    return (
        <div
            className={classNames(
                'flex items-center py-2 px-2 space-x-2 rounded-md',
                {
                    'bg-gray-100': !values.accepted,
                    'bg-green-100': values.accepted,
                }
            )}
        >
            <input
                id="approve-operation"
                type="checkbox"
                className="h-4 w-4 text-green-500 border-gray-300 rounded focus:ring-green-500"
                defaultChecked={values.accepted}
                disabled={disabled}
                onChange={() => setFieldValue('accepted', !values.accepted)}
            />

            <label
                htmlFor="approve-operation"
                className={classNames('text-sm', {
                    'text-gray-700': !disabled,
                    'text-gray-400': disabled,
                })}
            >
                Operacja zatwierdzona
            </label>
        </div>
    )
}

const ApproveImplantsField: React.FC<{
    disabled?: boolean
}> = ({ disabled }) => {
    const { values, setFieldValue } = useFormikContext<TScheduleOperationForm>()

    return (
        <div
            className={classNames(
                'flex items-center py-2 px-2 space-x-2 rounded-md bg-gray-100'
            )}
        >
            <input
                id="approve-implants"
                type="checkbox"
                className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
                defaultChecked={values.implants_approved}
                disabled={disabled}
                onChange={() =>
                    setFieldValue(
                        'implants_approved',
                        !values.implants_approved
                    )
                }
            />

            <label
                htmlFor="approve-implants"
                className={classNames('text-sm', {
                    'text-gray-700': !disabled,
                    'text-gray-400': disabled,
                })}
            >
                Implanty zamówione
            </label>
        </div>
    )
}

export const AssistantFieldArray: React.FC<{ disabled?: boolean }> = ({
    disabled = false,
}) => {
    const { values, errors } = useFormikContext<TScheduleOperationForm>()

    return (
        <FieldArray
            name="assistant"
            render={({ push, remove }) => (
                <>
                    {values.assistant.map((item, index) => (
                        <div
                            key={index}
                            className={classNames({
                                'mt-2': index > 0,
                            })}
                        >
                            <EmployeePickerField
                                name={`assistant.${index}`}
                                placeholder="Szukaj / wybierz lekarza"
                                disabled={disabled}
                                functions={['doctor function']}
                                messageEmptyResult="Nie znaleziono lekarza."
                                hasError={!!errors?.assistant?.[index]}
                                handleRemove={
                                    values.assistant.filter((item) => !!item)
                                        .length > 1
                                        ? () => remove(index)
                                        : index === 0
                                        ? undefined
                                        : () => remove(index)
                                }
                            />
                            <ErrorMessage name={`assistant.${index}`} />
                        </div>
                    ))}
                    {!disabled && (
                        <div className="mt-2">
                            <Button
                                variant="tertiary"
                                type="button"
                                size="sm"
                                className="w-full"
                                disabled={values.assistant.some(
                                    (item) => !item
                                )}
                                onClick={() => push(null)}
                            >
                                <div className="flex items-center">
                                    <span>Dodaj kolejną asystę</span>
                                    <PlusIcon className="ml-2 inline-flex w-4 h-4" />
                                </div>
                            </Button>
                        </div>
                    )}
                </>
            )}
        />
    )
}

export const AnesthesiologistFieldArray: React.FC<{
    disabled?: boolean
}> = ({ disabled = false }) => {
    const { values, errors } = useFormikContext<TScheduleOperationForm>()

    return (
        <FieldArray
            name="anesthesiologist"
            render={({ push, remove }) => (
                <>
                    {values.anesthesiologist.map((item, index) => (
                        <div
                            key={index}
                            className={classNames({
                                'mt-2': index > 0,
                            })}
                        >
                            <EmployeePickerField
                                name={`anesthesiologist.${index}`}
                                placeholder="Szukaj / wybierz anestezjologa"
                                disabled={disabled}
                                hasError={!!errors?.anesthesiologist?.[index]}
                                functions={['anesthesiologist function']}
                                messageEmptyResult="Nie znaleziono anestezjologa."
                                handleRemove={
                                    values.anesthesiologist.filter(
                                        (item) => !!item
                                    ).length > 1
                                        ? () => remove(index)
                                        : index === 0
                                        ? undefined
                                        : () => remove(index)
                                }
                            />
                            <ErrorMessage name={`anesthesiologist.${index}`} />
                        </div>
                    ))}
                    {!disabled && (
                        <div className="mt-2">
                            <Button
                                variant="tertiary"
                                type="button"
                                size="sm"
                                className="w-full"
                                disabled={values.anesthesiologist.some(
                                    (item) => !item
                                )}
                                onClick={() => push(null)}
                            >
                                <div className="flex items-center">
                                    <span>Dodaj kolejnego anestezjologa</span>
                                    <PlusIcon className="ml-2 inline-flex w-4 h-4" />
                                </div>
                            </Button>
                        </div>
                    )}
                </>
            )}
        />
    )
}

export const AnesthesiologistNurseFieldArray: React.FC<{
    disabled?: boolean
}> = ({ disabled = false }) => {
    const { values, errors } = useFormikContext<TScheduleOperationForm>()

    return (
        <FieldArray
            name="anesthesiologist_nurse"
            render={({ push, remove }) => (
                <>
                    {values.anesthesiologist_nurse.map((item, index) => (
                        <div
                            key={index}
                            className={classNames({
                                'mt-2': index > 0,
                            })}
                        >
                            <EmployeePickerField
                                name={`anesthesiologist_nurse.${index}`}
                                placeholder="Szukaj / wybierz pielęgniarkę"
                                disabled={disabled}
                                hasError={
                                    !!errors?.anesthesiologist_nurse?.[index]
                                }
                                functions={['anesthesiologist nurse function']}
                                messageEmptyResult="Nie znaleziono pielęgniarki."
                                handleRemove={
                                    values.anesthesiologist_nurse.filter(
                                        (item) => !!item
                                    ).length > 1
                                        ? () => remove(index)
                                        : index === 0
                                        ? undefined
                                        : () => remove(index)
                                }
                            />
                            <ErrorMessage
                                name={`anesthesiologist_nurse.${index}`}
                            />
                        </div>
                    ))}
                    {!disabled && (
                        <div className="mt-2">
                            <Button
                                variant="tertiary"
                                type="button"
                                size="sm"
                                className="w-full"
                                disabled={values.anesthesiologist_nurse.some(
                                    (item) => !item
                                )}
                                onClick={() => push(null)}
                            >
                                <div className="flex items-center">
                                    <span>Dodaj kolejnego anestezjologa</span>
                                    <PlusIcon className="ml-2 inline-flex w-4 h-4" />
                                </div>
                            </Button>
                        </div>
                    )}
                </>
            )}
        />
    )
}

export const OperationNurseFieldArray: React.FC<{
    disabled?: boolean
}> = ({ disabled = false }) => {
    const { values, errors } = useFormikContext<TScheduleOperationForm>()

    return (
        <FieldArray
            name="operation_nurse"
            render={({ push, remove }) => (
                <>
                    {values.operation_nurse.map((item, index) => (
                        <div
                            key={index}
                            className={classNames({
                                'mt-2': index > 0,
                            })}
                        >
                            <EmployeePickerField
                                name={`operation_nurse.${index}`}
                                placeholder="Szukaj / wybierz pielęgniarkę operacyjną"
                                disabled={disabled}
                                functions={['operation nurse function']}
                                messageEmptyResult="Nie znaleziono pielęgniarki operacyjnej."
                                hasError={!!errors?.operation_nurse?.[index]}
                                handleRemove={
                                    values.operation_nurse.filter(
                                        (item) => !!item
                                    ).length > 1
                                        ? () => remove(index)
                                        : index === 0
                                        ? undefined
                                        : () => remove(index)
                                }
                            />
                            <ErrorMessage name={`operation_nurse.${index}`} />
                        </div>
                    ))}
                    {!disabled && (
                        <div className="mt-2">
                            <Button
                                variant="tertiary"
                                size="sm"
                                className="w-full"
                                disabled={values.operation_nurse.some(
                                    (item) => !item
                                )}
                                onClick={() => push(null)}
                            >
                                <div className="flex items-center">
                                    <span>
                                        Dodaj kolejną pielęgniarkę operacyjną
                                    </span>
                                    <PlusIcon className="ml-2 inline-flex w-4 h-4" />
                                </div>
                            </Button>
                        </div>
                    )}
                </>
            )}
        />
    )
}

export default ScheduleOperationForm
