import React, { useContext } from 'react'
import { Formik, Form } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import moment from 'moment'

import {
    useCreateOperation,
    transformSurgeryFormToApi,
    useGetExpenses,
    setSurgicalTeamErrors,
} from 'api'
import { usePolicy, useSurgeryPolicy } from 'hooks'
import { validatePhoneNumber } from 'helpers'
import {
    API_FORMAT_DATE,
    EXPENSES_TYPE_HOSPITAL_DAY_COST,
} from 'constants/index'
import alertContext from 'contexts/alerts/alertContext'
import ListLayout from 'layouts/ListLayout'
import { AccessDenied } from 'components'
import { Button, Loader } from 'components/ui'
import DoctorSurgeryForm from 'components/DoctorSurgeryForm'

import type { ExpenseListResponse, PostOperation } from 'api/types'
import type { FormSubmitFn } from 'types'
import type { SurgeryForm } from 'types/SurgeryForm'

const DoctorSurgeryFormContainer: React.FC = () => {
    const { user } = usePolicy()
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const { canCreate } = useSurgeryPolicy()
    const { add: alert } = useContext(alertContext)

    const expenses = useGetExpenses(
        { type: EXPENSES_TYPE_HOSPITAL_DAY_COST },
        { enabled: canCreate }
    )
    const { mutate: create } = useCreateOperation<Partial<PostOperation>>()

    const handleSubmit: FormSubmitFn<SurgeryForm> = (values, formikHelpers) => {
        if (
            values.patient?.phone &&
            !validatePhoneNumber(values.patient.phone)
        ) {
            formikHelpers.setSubmitting(false)
            return alert({
                content:
                    'Niepoprawny numer telefonu pacjenta. Dopuszczalne tylko cyfry i znak +, maks. długość numeru 15 znaków',
                type: 'danger',
            })
        }
        const data = Object.assign({}, transformSurgeryFormToApi(values), {
            costs_resolved: undefined,
            implants_used: undefined,
        })

        create(data, {
            onSuccess: async () => {
                await queryClient.invalidateQueries('operations')
                await queryClient.invalidateQueries('schedules')
                formikHelpers.setSubmitting(false)
                navigate('/surgeries')
                alert({
                    content: 'Operacja poprawnie dodana.',
                    type: 'success',
                })
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)

                setSurgicalTeamErrors(
                    data.surgical_team,
                    error.errors,
                    formikHelpers.setFieldError
                )
            },
        })
    }

    if (!canCreate) {
        return (
            <AccessDenied message="Nie posiadasz uprawnień do dodawania operacji" />
        )
    }

    if ([expenses.isLoading].some((isLoading) => isLoading)) {
        return <Loader />
    }

    if (expenses.isError) {
        return <div>{expenses.error.message}</div>
    }

    return (
        <ListLayout
            title="Dodaj operację"
            actions={
                <>
                    <Button
                        as="link"
                        to="/surgeries/drafts/add"
                        variant="default"
                        className="hidden lg:inline-flex"
                    >
                        Operacje spoza cennika
                    </Button>
                </>
            }
        >
            <Formik
                initialValues={getInitialValues({
                    user,
                    expenses: expenses.data,
                })}
                onSubmit={handleSubmit}
            >
                <Form>
                    <DoctorSurgeryForm />
                </Form>
            </Formik>
        </ListLayout>
    )
}

const getInitialValues = ({
    user,
    expenses,
}: {
    expenses: ExpenseListResponse | undefined
    user: ReturnType<typeof usePolicy>['user']
}) => {
    return {
        surgical_team: [],
        doctor: user.isDoctor
            ? {
                  id: user.id,
                  first_name: user.first_name,
                  last_name: user.last_name,
                  avatar_url: user.avatar_url,
              }
            : undefined,
        assistant: [null, null],
        patient: undefined,
        _patient_item: undefined,
        _patient_item_phrase: '',
        anesthesia_type: undefined,
        equipment: [],
        _equipment_item: undefined,
        _equipment_item_phrase: '',
        estimated_date: moment().format(API_FORMAT_DATE),
        procedure: undefined,
        _procedure_item: undefined,
        _procedure_item_phrase: '',
        contains_implants: false,
        preferred_beginning_hour: undefined,
        estimated_duration: undefined,
        recovery_days: undefined,
        hospital_day_cost: expenses?.data[0]?.price || 0,
        additional_bed: 0,
        description: '',
        diagnosis: '',
        payer: undefined,
        base_cost: '0',
        implants: [],
        implants_used: [],
        _implant_item_phrase: '',
        _implant_item: undefined,
        _implant_price: '',
        _implant_set_item_phrase: '',
        _implant_set_item: undefined,
        costs: [],
        costs_resolved: [],
        _costs_item: undefined,
        _costs_item_phrase: '',
        _expense_price: '',
    } as SurgeryForm
}

export default DoctorSurgeryFormContainer
