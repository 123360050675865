import React, { Fragment, useMemo } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
    ArrowNarrowRightIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    ExclamationIcon,
} from '@heroicons/react/solid'
import { twMerge } from 'tailwind-merge'

import { useSchedulePlannerPolicyGroup } from 'hooks'
import { LiveViewOperationEventStatusContainer } from 'components/LiveViewOperationEventStatus'
import { Button, Loader } from 'components/ui'

import { ReactComponent as IconOperationRoomCleaning } from 'assets/operationProgressIcons/operation-room-cleaning.svg'
import { ReactComponent as IconOperationInProgress } from 'assets/operationProgressIcons/operation-in-progress.svg'
import { ReactComponent as IconOperationDone } from 'assets/operationProgressIcons/operation-done.svg'
import { ReactComponent as IconPatientCallUp } from 'assets/operationProgressIcons/operation-patient-call-up.svg'
import { ReactComponent as IconAdmittedToOperatingRoom } from 'assets/operationProgressIcons/operation-admitted-to-operating-block.svg'
import { ReactComponent as IconInTransferToBlock } from 'assets/operationProgressIcons/operation-in-transfer-to-block.svg'
import { ReactComponent as IconAwaitingForCall } from 'assets/operationProgressIcons/operation-awaiting-for-call.svg'
import { ReactComponent as IconAnesthesiaCompleted } from 'assets/operationProgressIcons/operation-anesthesia-completed.svg'
import { ReactComponent as IconReturningFromOperatingBlock } from 'assets/operationProgressIcons/operation-returning-from-operating-block.svg'

import type { ScheduleOperationEvent, ScheduleProcedureType } from 'api/types'

const STATUSES_DISALLOW_FINISH_NOW = [
    'awaiting_for_call',
    'calling_for_patient',
    'in_transfer_to_block',
    'admitted_to_operating_block',
    'patient_returned_to_room',
]

const ScheduleOperationStatus: React.FC<{
    isMoveStatusForwardLoading: boolean
    restoringStatus: string | null
    buttonFinishNowIsLoading: boolean
    editable: boolean
    isFullWidth: boolean
    event: ScheduleOperationEvent
    procedureType: ScheduleProcedureType
    handleUMoveStatusForward: (event: ScheduleOperationEvent) => void
    handleFinishNow: (event: ScheduleOperationEvent) => void
    handleRestoreStatus: (status: string) => void
    restoreStatuses: string[]
}> = ({
    editable,
    event,
    procedureType,
    isMoveStatusForwardLoading,
    buttonFinishNowIsLoading,
    handleUMoveStatusForward,
    handleFinishNow,
    isFullWidth,
    restoreStatuses,
    restoringStatus,
    handleRestoreStatus,
}) => {
    const isStatusForOperatingRoom = [
        'ready_for_cleaning',
        'cleaning',
        'cleaning_completed',
    ].includes(event.status)

    const isLocal = procedureType.type === 'local'
    const canFinishNow = !STATUSES_DISALLOW_FINISH_NOW.includes(event.status)
    const schedulePlannerPolicyGroup = useSchedulePlannerPolicyGroup()

    const statuses = useMemo(() => {
        const statusesList = isStatusForOperatingRoom
            ? [
                  {
                      status: 'ready_for_cleaning',
                      label: 'Oczekuje na sprzątanie',
                      icon: <IconOperationRoomCleaning />,
                      theme: 'light-purple',
                  },
                  {
                      status: 'cleaning',
                      label: 'Sprzątanie',
                      icon: <IconOperationRoomCleaning />,
                      theme: 'purple',
                  },
                  {
                      status: 'cleaning_completed',
                      label: 'Sprzątanie zakończone',
                      icon: <IconOperationRoomCleaning />,
                      theme: 'gray',
                  },
              ]
            : [
                  {
                      status: 'awaiting_for_call',
                      label: 'Oczekuje na zabieg',
                      icon: <IconAwaitingForCall />,
                      theme: 'common',
                  },
                  {
                      status: 'calling_for_patient',
                      label: 'Wezwanie pacjenta',
                      icon: <IconPatientCallUp />,
                      theme: 'light-orange',
                  },
                  {
                      status: 'in_transfer_to_block',
                      label: 'Przyjęte wezwanie',
                      icon: <IconInTransferToBlock />,
                      theme: 'cyan',
                  },
                  {
                      status: 'admitted_to_operating_block',
                      label: 'Przyjęcie na blok',
                      icon: <IconAdmittedToOperatingRoom />,
                      theme: 'cyan-inverse',
                  },
                  ...(isLocal
                      ? []
                      : [
                            {
                                status: 'initiation_of_anesthesia',
                                label: 'Znieczulenie',
                                icon: <IconAdmittedToOperatingRoom />,
                                theme: 'orange',
                            },
                        ]),
                  {
                      status: 'patient_anesthetized',
                      label: isLocal
                          ? 'Gotowy do zabiegu'
                          : 'Znieczulony, gotowy do zabiegu',
                      icon: <IconInTransferToBlock />,
                      theme: 'blue',
                  },
                  {
                      status: 'procedure_started',
                      label: 'W trakcie zabiegu',
                      icon: <IconOperationInProgress />,
                      theme: 'in-progress',
                  },
                  ...(isLocal
                      ? [
                            {
                                status: 'local_procedure_ended',
                                label: 'Zabieg zakończony',
                                icon: <IconOperationDone />,
                                theme: 'common',
                            },
                            {
                                status: 'calling_patient_pickup_from_operating_room',
                                label: 'Oczekiwanie na odbiór pacjenta',
                                icon: <IconReturningFromOperatingBlock />,
                                theme: 'light-purple',
                            },
                            {
                                status: 'returning_from_local_procedure',
                                label: 'Odbieranie pacjenta',
                                icon: <IconReturningFromOperatingBlock />,
                                theme: 'light-purple',
                            },
                        ]
                      : [
                            {
                                status: 'patient_in_recovery_after_procedure',
                                label: 'Zabieg zakończony',
                                icon: <IconOperationDone />,
                                theme: 'common',
                            },
                            {
                                status: 'anesthesia_completed',
                                label: 'Wybudzony po zabiegu',
                                icon: <IconAnesthesiaCompleted />,
                                theme: 'cyan',
                            },
                            {
                                status: 'calling_patient_pickup_from_operating_block',
                                label: 'Oczekiwanie na odbiór pacjenta',
                                icon: <IconReturningFromOperatingBlock />,
                                theme: 'light-purple',
                            },
                            {
                                status: 'returning_from_operating_block',
                                label: 'Odbieranie pacjenta',
                                icon: <IconReturningFromOperatingBlock />,
                                theme: 'light-purple',
                            },
                        ]),

                  {
                      status: 'patient_left_operating_block',
                      label: 'Pacjent w drodze do pokoju',
                      icon: <IconReturningFromOperatingBlock />,
                      theme: 'light-purple',
                  },
                  {
                      status: 'patient_returned_to_room',
                      label: 'Zabieg zakończony',
                      icon: <IconAwaitingForCall />,
                      theme: 'gray',
                  },
              ]

        const currentStatusIndex = statusesList.findIndex(
            (status) => status.status === event.status
        )

        return statusesList.map((status, index) => ({
            ...status,
            isCurrent: index === currentStatusIndex,
            isPast: index < currentStatusIndex,
            isFuture: index > currentStatusIndex,
            isNext: index === currentStatusIndex + 1,
            canBeRestored: restoreStatuses.includes(status.status),
        }))
    }, [isStatusForOperatingRoom, isLocal, event.status, restoreStatuses])

    if (!editable) {
        return (
            <div className="flex-1 w-full h-16">
                <LiveViewOperationEventStatusContainer
                    status={event.status}
                    isLocal={isLocal}
                />
            </div>
        )
    }

    return (
        <Popover key={event.id} className="flex-1">
            {({ open }) => (
                <>
                    <Popover.Button className="flex w-full h-16">
                        <LiveViewOperationEventStatusContainer
                            status={event.status}
                            isLocal={isLocal}
                            iconRight={
                                open ? (
                                    <ChevronUpIcon className="w-5" />
                                ) : (
                                    <ChevronDownIcon className="w-5" />
                                )
                            }
                        />
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel
                            className={twMerge(
                                'absolute z-10 w-1/2 left-1/4',
                                isStatusForOperatingRoom &&
                                    !isFullWidth &&
                                    'left-1/2',
                                !isStatusForOperatingRoom &&
                                    !isFullWidth &&
                                    'left-0'
                            )}
                        >
                            <div
                                className="overflow-auto bg-white shadow-lg divide-y divide-gray-300"
                                style={{ maxHeight: 'calc(100vh - 80px)' }}
                            >
                                <div className="px-4 py-5 flex flex-col items-center justify-center gap-2">
                                    {statuses.map((status, index) => (
                                        <div className="flex items-center justify-between gap-7 w-full">
                                            <span className="w-6 flex items-center justify-center">
                                                {(status.isPast ||
                                                    status.isCurrent) &&
                                                    status.canBeRestored && (
                                                        <span
                                                            className={twMerge(
                                                                'w-5 h-5 rounded-full bg-blue-300 inline-flex items-center justify-center text-white',
                                                                status.isCurrent &&
                                                                    'bg-blue-500'
                                                            )}
                                                        >
                                                            <CheckIcon className="w-4" />
                                                        </span>
                                                    )}
                                                {status.isNext && (
                                                    <ArrowNarrowRightIcon className="w-5 text-gray-400" />
                                                )}
                                                {!status.canBeRestored &&
                                                    !status.isNext && (
                                                        <div className="h-0.5 bg-gray-300 w-4" />
                                                    )}
                                            </span>
                                            <button
                                                disabled={
                                                    isMoveStatusForwardLoading ||
                                                    !!restoringStatus ||
                                                    (!schedulePlannerPolicyGroup.canChangeEvents &&
                                                        status.isNext) ||
                                                    (!status.canBeRestored &&
                                                        !status.isNext) ||
                                                    (status.canBeRestored &&
                                                        !schedulePlannerPolicyGroup.canRestoreStatus) ||
                                                    status.isCurrent
                                                }
                                                onClick={() => {
                                                    status.isNext
                                                        ? handleUMoveStatusForward(
                                                              event
                                                          )
                                                        : handleRestoreStatus(
                                                              status.status
                                                          )
                                                }}
                                                className={twMerge(
                                                    'flex items-center justify-center flex-1 h-10 rounded-lg font-medium border border-dashed border-transparent cursor-not-allowed',
                                                    status.theme ===
                                                        'in-progress' &&
                                                        'bg-green-600 text-white',
                                                    status.theme === 'common' &&
                                                        'bg-gray-100 text-gray-600',
                                                    status.theme === 'orange' &&
                                                        'bg-yellow-500 text-white',
                                                    status.theme ===
                                                        'light-orange' &&
                                                        'bg-yellow-100 text-yellow-700',
                                                    status.theme === 'blue' &&
                                                        'bg-blue-500 text-white',
                                                    status.theme === 'cyan' &&
                                                        'bg-cyan text-cyan-dark',
                                                    status.theme ===
                                                        'cyan-inverse' &&
                                                        'bg-cyan-dark text-cyan',
                                                    status.theme === 'purple' &&
                                                        'bg-purple-800 text-white',
                                                    status.theme ===
                                                        'light-purple' &&
                                                        'bg-purple-100 text-purple-800',
                                                    status.theme === 'gray' &&
                                                        'bg-gray-400 text-gray-800',
                                                    status.isNext ||
                                                        status.canBeRestored
                                                        ? 'cursor-pointer'
                                                        : 'bg-gray-50 text-gray-300',
                                                    (status.isCurrent ||
                                                        (!schedulePlannerPolicyGroup.canRestoreStatus &&
                                                            status.canBeRestored)) &&
                                                        'cursor-not-allowed'
                                                )}
                                            >
                                                {(isMoveStatusForwardLoading &&
                                                    status.isNext) ||
                                                restoringStatus ===
                                                    status.status ? (
                                                    <Loader size="xs" />
                                                ) : (
                                                    <>
                                                        <span
                                                            className={twMerge(
                                                                'rounded-full w-6 h-6 mr-2',
                                                                'bg-red-100 text-red-800',
                                                                status.theme ===
                                                                    'in-progress' &&
                                                                    'bg-white text-green-600',
                                                                status.theme ===
                                                                    'common' &&
                                                                    'bg-gray-300 text-gray-600',
                                                                status.theme ===
                                                                    'orange' &&
                                                                    'bg-white text-yellow-600',
                                                                status.theme ===
                                                                    'light-orange' &&
                                                                    'bg-yellow-700 text-white',
                                                                status.theme ===
                                                                    'blue' &&
                                                                    'bg-white text-blue-800',
                                                                status.theme ===
                                                                    'cyan' &&
                                                                    'bg-cyan-light text-cyan-dark',
                                                                status.theme ===
                                                                    'cyan-inverse' &&
                                                                    'bg-cyan-light text-cyan-dark',
                                                                status.theme ===
                                                                    'purple' &&
                                                                    'bg-white text-purple-800',
                                                                status.theme ===
                                                                    'light-purple' &&
                                                                    'bg-purple-800 text-white',
                                                                status.theme ===
                                                                    'gray' &&
                                                                    'bg-gray-200 text-gray-800',
                                                                !status.isNext &&
                                                                    !status.canBeRestored &&
                                                                    'bg-gray-50 text-gray-300'
                                                            )}
                                                        >
                                                            {status.icon}
                                                        </span>
                                                        {status.label}
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                                <div className="p-2">
                                    <div className="flex px-3 py-2 items-center justify-between bg-gray-200 rounded-md">
                                        <div className="flex items-center flex-grow">
                                            <ExclamationIcon className="relative top-0.5 w-10 text-red-600" />
                                            <div className="ml-3">
                                                {isStatusForOperatingRoom ? (
                                                    <p className="text-mg leading-6 font-medium text-gray-700">
                                                        Pomiń sprzątanie
                                                    </p>
                                                ) : (
                                                    <>
                                                        <p className="text-mg leading-6 font-medium text-gray-700">
                                                            Zakończ operację
                                                        </p>
                                                        <p className="text-sm leading-5 font-normal text-gray-500">
                                                            Wymuś zakończenie
                                                            operacji natychmiast
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Button
                                                disabled={
                                                    isStatusForOperatingRoom
                                                        ? !schedulePlannerPolicyGroup.canChangeEvents ||
                                                          event.status !==
                                                              'ready_for_cleaning'
                                                        : !schedulePlannerPolicyGroup.canFinishOperation ||
                                                          !canFinishNow
                                                }
                                                loading={
                                                    buttonFinishNowIsLoading
                                                }
                                                onClick={() =>
                                                    handleFinishNow(event)
                                                }
                                            >
                                                {isStatusForOperatingRoom ? (
                                                    <span>
                                                        Pomiń sprzątanie
                                                    </span>
                                                ) : (
                                                    <span>
                                                        Zakończ operację
                                                    </span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}

export default ScheduleOperationStatus
