import React from 'react'

import ListLayout from './ListLayout'

import type { TTab } from 'components/SettingsTabs'

const tabs: TTab[] = [
    {
        name: 'Implanty',
        to: '/settings/price-list/implants',
        current: true,
    },
    {
        name: 'Zestawy implantów',
        to: '/settings/price-list/implant-sets',
        current: false,
    },
    {
        name: 'Producenci',
        to: '/settings/price-list/implant-manufacturers',
        current: false,
    },
    {
        name: 'Sprzęt medyczny',
        to: '/settings/price-list/medical-equipment',
        current: false,
    },
    {
        name: 'Inne koszty',
        to: '/settings/price-list/expenses',
        current: false,
    },
    {
        name: 'Koszty hospitalizacji',
        to: '/settings/price-list/expenses-other',
        current: false,
    },
]

export default function SettingsPriceListLayout({
    children,
    actions,
}: {
    children?: React.ReactChild
    actions?: React.ReactChild | boolean
}) {
    return (
        <ListLayout title="Cennik" tabs={tabs} actions={actions}>
            {children}
        </ListLayout>
    )
}
