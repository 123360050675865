import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { MESSAGES } from 'constants/index'
import { useNotification, useModal } from 'hooks'
import {
    useRenewOperation,
    useUpdateOperation,
    useUpdateScheduleOperation,
} from 'api'

import type { FormSubmitFn } from 'types'
import type { ScheduleOperationUpdateVariables, ResponseError } from 'api/types'
import type { OperationRejectModalState } from 'components/OperationRejectModal'
import type { OperationRenewModalState } from 'components/OperationRenewModal'
import type { OperationCancelModalState } from 'components/OperationCancelModal'
import type { OperationRejectForm } from 'types/OperationRejectForm'
import type { OperationRenewForm } from 'types/OperationRenewForm'
import type { OperationCancelForm } from 'types/OperationCancelForm'

export function useScheduleActions(
    resource: 'operation' | 'schedule-operation'
) {
    const queryClient = useQueryClient()
    const showNotification = useNotification()
    const { mutate: updateOperation } = useUpdateOperation()
    const { mutate: updateScheduleOperation } = useUpdateScheduleOperation()
    const { mutate: actionRenew } = useRenewOperation()

    const [rejectedId, setRejectedId] = useState<number | null>(null)
    const [renewedId, setRenewedId] = useState<number | null>(null)
    const [canceledId, setCanceledId] = useState<number | null>(null)
    const modalReject = useModal<OperationRejectModalState>()
    const modalRenew = useModal<OperationRenewModalState>()
    const modalCancel = useModal<OperationCancelModalState>()

    const update =
        resource === 'operation' ? updateOperation : updateScheduleOperation

    const reject = (
        variables: ScheduleOperationUpdateVariables,
        options?: {
            onSuccess?: () => void
            onError?: (error: ResponseError) => void
            onSettled?: () => void
        }
    ) => {
        updateScheduleOperation(variables, {
            onSuccess: () => {
                showNotification({
                    content: MESSAGES.SURGERY_SET_STATUS_REJECTED_SUCCESS,
                    type: 'success',
                })

                queryClient.invalidateQueries(['operations'])
                queryClient.invalidateQueries(['schedules'])

                options?.onSuccess && options.onSuccess()
            },
            onError: (error) => {
                showNotification({
                    content: MESSAGES.SURGERY_SET_STATUS_REJECTED_FAILED,
                    type: 'danger',
                })

                options?.onError && options.onError(error)
            },
            onSettled: () => {
                options?.onSettled && options.onSettled()
            },
        })
    }

    const handleReject = (id: number) => {
        modalReject.setState({ id })
        modalReject.openModal()
    }

    const handleRejectFormSubmit: FormSubmitFn<OperationRejectForm> = (
        data,
        formikHelpers
    ) => {
        const id = modalReject.getState()?.id

        if (!id) {
            return
        }

        setRejectedId(id)

        reject(
            { id, data },
            {
                onSuccess: () => {
                    modalReject.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                },
                onSettled: () => {
                    formikHelpers.setSubmitting(false)
                    setRejectedId(null)
                },
            }
        )
    }

    const renew = (
        variables: {
            id: OperationRenewModalState['id']
            data: OperationRenewForm
        },
        options?: {
            onSuccess?: () => void
            onError?: (error: ResponseError) => void
            onSettled?: () => void
        }
    ) => {
        actionRenew(
            {
                id: variables.id,
                data: variables.data,
            },
            {
                onSuccess: () => {
                    showNotification({
                        content: MESSAGES.SURGERY_RESTORE_SUCCESS,
                        type: 'success',
                    })

                    queryClient.invalidateQueries(['operations'])
                    queryClient.invalidateQueries(['schedules'])

                    options?.onSuccess && options.onSuccess()
                },
                onError: (error) => {
                    showNotification({
                        content: MESSAGES.SURGERY_RESTORE_FAILED,
                        type: 'danger',
                    })

                    options?.onError && options.onError(error)
                },
                onSettled: () => {
                    options?.onSettled && options.onSettled()
                },
            }
        )
    }

    const handleRenew = (data: { id: number; estimated_date: string }) => {
        modalRenew.setState(data)
        modalRenew.openModal()
    }

    const handleRenewFormSubmit: FormSubmitFn<OperationRenewForm> = (
        data,
        formikHelpers
    ) => {
        const id = modalRenew.getState()?.id

        if (!id) {
            return
        }

        setRenewedId(id)

        renew(
            { id, data },
            {
                onSuccess: () => {
                    modalRenew.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                },
                onSettled: () => {
                    formikHelpers.setSubmitting(false)
                    setRenewedId(null)
                },
            }
        )
    }

    const handleCancel = (id: number) => {
        modalCancel.setState({ id })
        modalCancel.openModal()
    }

    const handleCancelFormSubmit: FormSubmitFn<OperationCancelForm> = (
        data,
        formikHelpers
    ) => {
        const id = modalCancel.getState()?.id

        if (!id) {
            return
        }

        setCanceledId(id)

        update(
            { id, data },
            {
                onSuccess: () => {
                    modalCancel.closeModal()
                    showNotification({
                        content: MESSAGES.SURGERY_SET_STATUS_CANCELED_SUCCESS,
                        type: 'success',
                    })
                    queryClient.invalidateQueries(['operations'])
                    queryClient.invalidateQueries(['schedules'])
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                },
                onSettled: () => {
                    formikHelpers.setSubmitting(false)
                    setCanceledId(null)
                },
            }
        )
    }

    const handleResignCancelling = () => {
        modalCancel.closeModal()
    }

    const handleResignRejecting = () => {
        modalReject.closeModal()
    }

    const handleResignRenewing = () => {
        modalRenew.closeModal()
    }

    const isMutating = (id: number) => {
        return rejectedId === id || renewedId === id || canceledId === id
    }

    return {
        modalCancel,
        modalReject,
        modalRenew,
        rejectedId,
        renewedId,
        canceledId,
        reject,
        renew,
        handleReject,
        handleRenew,
        handleCancel,
        handleRejectFormSubmit,
        handleRenewFormSubmit,
        handleCancelFormSubmit,
        handleResignRejecting,
        handleResignRenewing,
        handleResignCancelling,
        isMutating,
    }
}
