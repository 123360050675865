import React, { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { ExclamationCircleIcon, ThumbUpIcon } from '@heroicons/react/solid'

import { PAYER_TYPE_NFZ, OPERATION_STATUS_CANCELED } from 'constants/index'
import { useUpdateOperation } from '../api'
import {
    useDoctorSurgeryPolicyGroup,
    useModal,
    usePatientPolicy,
    usePolicy,
} from 'hooks'
import {
    roundedPrice,
    formatDate,
    displayAdmissionDate,
    displayPatientNameOrPESELOrIdentityCard,
    displayUserName,
} from 'helpers'
import { Spinner, Loader } from './ui'
import OperationStatus from 'components/OperationStatus'
import Sort, { SetSortingFn } from 'components/Sort'
import ApprovalCheckboxForm from 'components/SurgeryListApprovalCheckboxForm'
import StatusBackground, {
    twBgColor,
} from 'components/SurgeryListStatusBackground'
import SurgeryListItemMenu3dots from 'components/SurgeryListItemMenu3dots'
import HistoryModal from './HistoryModal'

import type { UseInfiniteQueryResult } from 'react-query'
import type { ResponseList, ResponseError } from 'api/types'
import type { OperationListItem } from 'types/entities'
import type { ISurgeryListFilters } from 'types/SurgeryList'

import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { twMerge } from 'tailwind-merge'

type SurgeryListProps = {
    surgeryQueryResult: UseInfiniteQueryResult<
        ResponseList<OperationListItem[]>,
        ResponseError
    >
    filtersCount: number
    filters: ISurgeryListFilters
    setSorting: SetSortingFn
}

const SurgeryList: React.FC<SurgeryListProps> = ({
    surgeryQueryResult,
    filters,
    filtersCount,
    setSorting,
}) => {
    const navigate = useNavigate()
    const policy = usePolicy()
    const patientPolicy = usePatientPolicy()
    const doctorSurgeryPolicyGroup = useDoctorSurgeryPolicyGroup()

    const [operationToUpdate, setOperationToUpdate] = useState<{
        id: number
        value: boolean
    } | null>(null)
    const modalHistory = useModal(false)
    const [historyOperationId, setHistoryOperationId] = useState<
        number | undefined
    >()

    const { mutate: updateOperation, isLoading: isUpdatingOperation } =
        useUpdateOperation({
            onSuccess: () => {
                surgeryQueryResult
                    .refetch()
                    .finally(() => setOperationToUpdate(null))
            },
        })

    useEffect(() => {
        !!operationToUpdate &&
            updateOperation({
                id: operationToUpdate.id,
                data: {
                    patient_to_be_admitted: operationToUpdate.value,
                },
            })
    }, [operationToUpdate, updateOperation])

    useEffect(() => {
        historyOperationId && modalHistory.openModal()
    }, [historyOperationId]) // eslint-disable-line

    const surgeriesNotFound =
        surgeryQueryResult.isSuccess &&
        surgeryQueryResult.data.pages.at(0)?.meta.total === 0

    const observerRef = useRef(null)
    const handleObserver = useCallback(
        (entries) => {
            const [target] = entries
            if (
                target.isIntersecting &&
                surgeryQueryResult.hasNextPage &&
                !surgeryQueryResult.isFetchingNextPage
            ) {
                surgeryQueryResult.fetchNextPage()
            }
        },
        [surgeryQueryResult]
    )

    useEffect(() => {
        if (!observerRef.current) {
            return
        }
        const element = observerRef.current
        const option = { threshold: 0 }
        const observer = new IntersectionObserver(handleObserver, option)
        observer.observe(element)
        return () => observer.unobserve(element)
    })

    const canSeePatientDetails = patientPolicy.canUpdate
    const canSeeSurgeryDetails = doctorSurgeryPolicyGroup.canShow

    return (
        <div className="p-2 bg-white rounded-lg print:p-0">
            <table className="w-full">
                <thead className="bg-gray-50 border-b border-b-gray-200 text-xs text-gray-500 leading-normal uppercase print:text-black">
                    <tr>
                        <th
                            scope="col"
                            className="px-1 md:px-2 xl:px-2 xl:pr-0 py-3 text-left font-medium tracking-wider truncate print:p-1"
                            style={{ width: '1%' }}
                        >
                            <Sort
                                name="estimated_date"
                                filters={filters}
                                setSorting={setSorting}
                                up
                            >
                                D. zabiegu
                            </Sort>
                            <Sort
                                name="created_at"
                                filters={filters}
                                setSorting={setSorting}
                                up
                            >
                                D. dodania
                            </Sort>
                        </th>
                        <th
                            scope="col"
                            className="px-1 md:px-2 xl:px-4 xl:pr-0 py-3 box-content font-medium tracking-wider text-left print:hidden print:p-1"
                            style={{ width: '1%' }}
                        >
                            {policy.user.isPlanner ? (
                                <span>
                                    Status
                                    <br />
                                    Implanty
                                </span>
                            ) : (
                                <span>Postęp</span>
                            )}
                        </th>
                        <th
                            scope="col"
                            className="px-1 md:px-2 py-3 text-center font-medium tracking-wider hidden md:table-cell print:table-cell print:p-1"
                            style={{ width: '1%' }}
                        >
                            <span className="print:hidden">Przyjęcie</span>
                            <span className="hidden print:inline">Przyj.</span>
                        </th>
                        <th
                            scope="col"
                            className="px-1 md:px-2 xl:px-6 xl:pr-0 py-3 text-left font-medium tracking-wider print:p-1"
                            style={{ width: '10%' }}
                        >
                            <Sort
                                name="patient_last_name"
                                filters={filters}
                                setSorting={setSorting}
                            >
                                Pacjent
                            </Sort>
                        </th>
                        <th
                            scope="col"
                            className="px-1 md:px-2 xl:px-6 xl:pr-0 py-3 text-left font-medium tracking-wider hidden md:table-cell print:table-cell print:p-1"
                            style={{ width: '10%' }}
                        >
                            <span className="block">Operator</span>
                            <span className="block">Asysta</span>
                        </th>
                        <th
                            scope="col"
                            className="px-1 md:px-2 xl:px-6 xl:pr-0 py-3 text-left font-medium tracking-wider hidden md:table-cell print:table-cell print:p-1"
                            style={{ width: '15%' }}
                        >
                            <span>
                                Nazwa
                                <br />
                                zabiegu
                            </span>
                        </th>
                        <th
                            scope="col"
                            className="w-auto px-1 md:px-2 xl:px-6 xl:pr-0 py-3 text-left font-medium tracking-wider hidden xl:table-cell print:table-cell print:p-1"
                        >
                            <span className="block">Uwagi do zabiegu</span>
                            <span className="block">Uwagi opiekunek</span>
                            <span className="block">Przyczyna zrzucenia</span>
                        </th>
                        <th
                            scope="col"
                            className="px-1 md:px-2 xl:px-6 xl:pr-0 py-3 text-left font-medium tracking-wider hidden md:table-cell print:hidden print:p-1"
                            style={{ width: '1%' }}
                        >
                            Status
                        </th>
                        <th
                            scope="col"
                            className="px-1 md:px-2 xl:px-6 xl:pr-0 py-3 text-left font-medium tracking-wider hidden xl:table-cell print:table-cell print:p-1"
                            style={{ width: '1%' }}
                        >
                            Płatnik
                            <br />
                            Cena
                        </th>
                        <th
                            scope="col"
                            className="px-1 md:px-2 xl:px-6 xl:pr-3 py-3 xl:text-right font-medium tracking-wider print:hidden print:p-1"
                            style={{ width: '1%' }}
                        >
                            Szczegóły
                        </th>
                    </tr>
                </thead>
                {surgeryQueryResult.isSuccess && surgeryQueryResult.isFetched && (
                    <tbody
                        className={classNames(
                            'text-sm bg-white divide-y leading-5 print:text-black',
                            {
                                'divide-gray-200': !policy.user.isCaregiver,
                                'divide-gray-300': policy.user.isCaregiver,
                            }
                        )}
                    >
                        {surgeryQueryResult.data.pages.map((page, pageIndex) =>
                            page.data.map((item, index) => (
                                <tr
                                    key={item.id}
                                    className={classNames(
                                        policy.user.isCaregiver
                                            ? twBgColor(item)
                                            : '',
                                        {
                                            'bg-gray-50':
                                                !policy.user.isCaregiver &&
                                                index % 2,
                                            'text-gray-500':
                                                item.status !==
                                                OPERATION_STATUS_CANCELED,
                                            'text-gray-400':
                                                item.status ===
                                                OPERATION_STATUS_CANCELED,
                                        }
                                    )}
                                >
                                    <td className="px-1 md:px-2 xl:px-2 xl:pr-0 py-3 print:p-1">
                                        <div className="text-sm leading-5 font-normal text-gray-900 print:text-black">
                                            {formatDate(item.estimated_date)}
                                        </div>
                                        <div className="text-xs leading-4 font-normal text-gray-500 print:text-black">
                                            {item.created_at
                                                ? formatDate(item.created_at)
                                                : '-'}
                                        </div>
                                    </td>
                                    <td className="px-1 md:px-2 xl:px-4 xl:pr-0 py-1 print:hidden print:p-1">
                                        <div className="flex flex-row items-center justify-center">
                                            {policy.user.isPlanner ? (
                                                <ApprovalCheckboxForm
                                                    item={item}
                                                />
                                            ) : (
                                                <StatusBackground item={item}>
                                                    <OperationStatus
                                                        progress={
                                                            (item.progress
                                                                ?.percent ||
                                                                0) * 100
                                                        }
                                                        rejected={
                                                            item.status ===
                                                            'canceled'
                                                        }
                                                    />
                                                </StatusBackground>
                                            )}
                                        </div>
                                    </td>
                                    <td className="px-1 md:px-2 py-1 text-center hidden md:table-cell print:table-cell print:p-1">
                                        <div className="flex items-center justify-around">
                                            {item.patient_informed && (
                                                <div className="mb-1">
                                                    <ThumbUpIcon className="inline w-4 text-green-500" />
                                                </div>
                                            )}

                                            {operationToUpdate?.id ===
                                            item.id ? (
                                                <Loader size="xs" />
                                            ) : (
                                                <>
                                                    {policy.user
                                                        .isCaregiver && (
                                                        <Tippy content="Do przyjęcia">
                                                            <input
                                                                type="checkbox"
                                                                className={classNames(
                                                                    'h-4 w-4 border-gray-300 rounded cursor-pointer'
                                                                )}
                                                                checked={
                                                                    !!item.patient_to_be_admitted
                                                                }
                                                                disabled={
                                                                    isUpdatingOperation ||
                                                                    surgeryQueryResult.isFetching
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    setOperationToUpdate(
                                                                        {
                                                                            id: item.id,
                                                                            value: event
                                                                                .target
                                                                                .checked,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </Tippy>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            {item.admission_date ? (
                                                <span className="font-medium print:text-black">
                                                    {displayAdmissionDate(
                                                        item.admission_date,
                                                        item.estimated_date
                                                    )}
                                                </span>
                                            ) : (
                                                '-'
                                            )}
                                        </div>
                                    </td>
                                    <td className="px-1 md:px-2 xl:px-6 xl:pr-0 py-3 print:p-1">
                                        <span
                                            className={classNames(
                                                'font-medium inline-flex items-center gap-1 print:text-black',
                                                {
                                                    'text-gray-900':
                                                        item.status !==
                                                        OPERATION_STATUS_CANCELED,
                                                    'text-gray-400':
                                                        item.status ===
                                                        OPERATION_STATUS_CANCELED,
                                                }
                                            )}
                                        >
                                            <span
                                                className={twMerge(
                                                    canSeePatientDetails &&
                                                        'hover:text-gray-500 cursor-pointer'
                                                )}
                                                onClick={() =>
                                                    canSeePatientDetails
                                                        ? navigate(
                                                              `/patients/${item.patient.id}/stats`
                                                          )
                                                        : undefined
                                                }
                                            >
                                                {item.patient
                                                    ? displayPatientNameOrPESELOrIdentityCard(
                                                          item.patient
                                                      )
                                                    : '-'}
                                            </span>
                                            {item.restored ? (
                                                <ExclamationCircleIcon
                                                    className="w-4 h-4 text-red-500"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </span>
                                    </td>
                                    <td className="px-1 md:px-2 xl:px-6 xl:pr-0 py-3 hidden md:table-cell print:table-cell print:p-1">
                                        <span
                                            className={classNames(
                                                'font-medium inline-block print:text-black',
                                                {
                                                    'text-gray-900':
                                                        item.status !==
                                                        OPERATION_STATUS_CANCELED,
                                                    'text-gray-400':
                                                        item.status ===
                                                        OPERATION_STATUS_CANCELED,
                                                }
                                            )}
                                        >
                                            {displayUserName(item.operator) ||
                                                '-'}
                                        </span>
                                        <span className="text-gray-400 flex flex-col print:text-black">
                                            {item.assistants.map(
                                                (assistant) => (
                                                    <span key={assistant.id}>
                                                        {displayUserName(
                                                            assistant
                                                        )}
                                                    </span>
                                                )
                                            )}
                                        </span>
                                    </td>
                                    <td className="px-1 md:px-2 xl:px-6 xl:pr-0 py-3 hidden md:table-cell print:table-cell print:text-black print:p-1">
                                        <span
                                            className={twMerge(
                                                canSeeSurgeryDetails &&
                                                    'hover:text-gray-400 cursor-pointer'
                                            )}
                                            onClick={() =>
                                                canSeeSurgeryDetails
                                                    ? navigate(
                                                          `/surgeries/${item.id}/card`
                                                      )
                                                    : undefined
                                            }
                                        >
                                            {item.procedure?.name || '-'}
                                        </span>
                                    </td>
                                    <td className="px-1 md:px-2 xl:px-6 xl:pr-0 py-1.5 hidden xl:table-cell print:table-cell print:p-1">
                                        <p
                                            className={classNames(
                                                'whitespace-pre-line break-words divide-y print:text-black',
                                                {
                                                    'text-gray-900':
                                                        item.status !==
                                                        OPERATION_STATUS_CANCELED,
                                                    'text-gray-400':
                                                        item.status ===
                                                        OPERATION_STATUS_CANCELED,
                                                }
                                            )}
                                        >
                                            <span className="block pb-1">
                                                {item.description || '-'}
                                            </span>
                                            <span className="block py-1">
                                                {item.caregivers_comments ||
                                                    '-'}
                                            </span>
                                            <span className="block pt-1">
                                                {item.reject_reason || '-'}
                                            </span>
                                        </p>
                                    </td>
                                    <td className="px-1 md:px-2 xl:px-6 xl:pr-0 py-3 hidden md:table-cell print:hidden print:p-1">
                                        {item.actual_state && (
                                            <span
                                                className={classNames(
                                                    'inline-flex items-center space-x-2 rounded-full px-2 text-xs leading-5',
                                                    {
                                                        'bg-gray-100 text-gray-800':
                                                            'awaiting' ===
                                                            item.actual_state,
                                                        'bg-red-100 text-red-800':
                                                            'ongoing' ===
                                                            item.actual_state,
                                                        'bg-green-100 text-green-800':
                                                            'finished' ===
                                                            item.actual_state,
                                                    }
                                                )}
                                            >
                                                {'awaiting' ===
                                                    item.actual_state && (
                                                    <span>Oczekuje</span>
                                                )}
                                                {'ongoing' ===
                                                    item.actual_state && (
                                                    <span>Trwa</span>
                                                )}
                                                {'finished' ===
                                                    item.actual_state && (
                                                    <span>Wykonana</span>
                                                )}
                                            </span>
                                        )}
                                        {!item.actual_state && <span>-</span>}
                                    </td>
                                    <td className="px-1 md:px-2 xl:px-6 xl:pr-0 py-3 hidden xl:table-cell print:table-cell print:text-black print:p-1">
                                        {!!item.payer ? (
                                            item.payer.type ===
                                            PAYER_TYPE_NFZ ? (
                                                <span>NFZ</span>
                                            ) : (
                                                <div>
                                                    <p>Komercyjny</p>
                                                    {!!item.total_cost && (
                                                        <p>
                                                            {roundedPrice(
                                                                item.total_cost
                                                            )}
                                                        </p>
                                                    )}
                                                </div>
                                            )
                                        ) : (
                                            <span>-</span>
                                        )}
                                    </td>
                                    <td className="px-1 md:px-2 xl:px-6 xl:pr-3 py-3 whitespace-nowrap print:hidden print:p-1">
                                        <SurgeryListItemMenu3dots
                                            item={item}
                                            showHistoryModal={() =>
                                                setHistoryOperationId(item.id)
                                            }
                                        />
                                    </td>
                                </tr>
                            ))
                        )}
                        {!surgeriesNotFound && surgeryQueryResult.hasNextPage && (
                            <tr ref={observerRef}>
                                <td colSpan={10}>
                                    <div className="flex items-center justify-center h-20">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="animate-spin text-gray-600"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 48 48"
                                        >
                                            <path
                                                fillOpacity="0.3"
                                                d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,44 C35.045695,44 44,35.045695 44,24 C44,12.954305 35.045695,4 24,4 C12.954305,4 4,12.954305 4,24 C4,35.045695 12.954305,44 24,44 Z"
                                            />
                                            <path d="M24,0 C37.254834,0 48,10.745166 48,24 L44,24 C44,12.954305 35.045695,4 24,4 L24,0 Z" />
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                )}
            </table>
            {surgeryQueryResult.isLoading && (
                <div className="divide-y divide-gray-200 text-sm leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (item, index) => (
                            <div key={index} className="relative h-16">
                                {index === 5 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        <Spinner className="p-0" />
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            {surgeriesNotFound && (
                <div className="divide-y divide-gray-200 text-md text-gray-500 leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (item, index) => (
                            <div key={index} className="relative h-16">
                                {index === 3 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        {filtersCount === 0 && (
                                            <span>
                                                Nie ma jeszcze żadnych operacji.
                                            </span>
                                        )}
                                        {filtersCount > 0 && (
                                            <span>
                                                Nie znaleziono wyników
                                                wyszukiwania.
                                            </span>
                                        )}
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}

            <HistoryModal
                modal={modalHistory}
                operationId={historyOperationId}
                listTypes={['descriptions', 'caregiver-comments']}
                onClose={() =>
                    modalHistory.closeModal(() =>
                        setHistoryOperationId(undefined)
                    )
                }
            />
        </div>
    )
}

export default SurgeryList
