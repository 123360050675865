import React from 'react'

import SettingsTabs, { TTab } from 'components/SettingsTabs'

const tabs: TTab[] = [
    {
        name: 'Kategorie',
        to: '/settings/procedure-categories',
        current: true,
    },
    {
        name: 'Rodzaje zabiegu',
        to: '/settings/procedure-types',
        current: false,
    },
    { name: 'Kontrahenci', to: '/settings/payers', current: false },
    { name: 'Operacje', to: '/settings/procedures', current: false },
    {
        name: 'Koszty operacji',
        to: '/settings/procedure-costs',
        current: false,
    },
]

export default function SettingsLayout({
    children,
    actions,
}: {
    children?: React.ReactChild
    actions?: React.ReactChild | boolean
}) {
    return (
        <div className="py-6">
            <div className="px-4 mb-4 sm:px-6 md:px-8">
                <div className="flex items-center justify-between">
                    <div className="flex-1">
                        <h2 className="text-2xl leading-8">
                            <span className="font-semibold">Operacje</span>
                        </h2>
                    </div>
                    <div className="flex-1 grow">
                        <SettingsTabs tabs={tabs} />
                    </div>
                    <div className="flex-1 flex items-center justify-end md:mt-0 md:ml-4">
                        {actions}
                    </div>
                </div>
            </div>
            <div className="px-4 sm:px-6 md:px-8">{children}</div>
        </div>
    )
}
